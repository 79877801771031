import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Router } from '@angular/router';
import { ENV } from '../core/env.config';
import { catchError, retry } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})


export class DatacatchingService {

  public url: any = "http://localhost:3000/api/";

  private toggleMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  public toggleMenu$ = this.toggleMenu.asObservable();
  public drsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public drsSubject$ = this.drsSubject.asObservable();

  private logos: BehaviorSubject<any> = new BehaviorSubject(false);
  public logos$ = this.logos.asObservable();
  
  setLogosEvent(val){
    this.logos.next(val)
  }

  settoggleMenu(cls) {
    this.toggleMenu.next(cls);
  }

  setDrSubject(ar) {
    this.drsSubject.next(ar);
  }
  constructor(private http: HttpClient, private router: Router) { }

  getApi(url: string, isTokenNotExpired: any, requestType: string): Observable<any> {
    
    if (isTokenNotExpired) {
      let is_localhost = ENV.IS_LOCALHOST ? true : false;
      let verifyParamExist = url.includes("?") ? "&" : "?";
      return this.http.get(`${url}${verifyParamExist}is_localhost=${is_localhost}`, this.getHttpOptions(requestType)).pipe(retry(2),catchError(errorResponse => this.handleError(errorResponse, this.router)));
    } else {
      this.router.navigate(['']);
    } 
  }


  postApi(url, payLoad, isTokenNotExpired: any, requestType:string): Observable<any> {
    if (isTokenNotExpired) {
      let is_localhost = ENV.IS_LOCALHOST ? true : false;
      let verifyParamExist = url.includes("?") ? "&" : "?"; 
      console.log('file', payLoad)
      console.log('http options', this.getHttpOptions(requestType))
      return this.http.post(`${url}${verifyParamExist}is_localhost=${is_localhost}`, payLoad, this.getHttpOptions(requestType)).pipe(retry(2), catchError(errorResponse => this.handleError(errorResponse, this.router)));
    } else {
      this.router.navigate(['']);
    }  
  } 


  updateApi(url, payLoad, isTokenNotExpired: any): Observable<any>{
    if (isTokenNotExpired) {
      let is_localhost = ENV.IS_LOCALHOST ? true : false;
      let verifyParamExist = url.includes("?") ? "&" : "?";
      return this.http.put(`${url}${verifyParamExist}is_localhost=${is_localhost}`, payLoad, this.getHttpOptions('normal')).pipe(retry(2), catchError(errorResponse => this.handleError(errorResponse, this.router)));
    } else {
      this.router.navigate(['']);
    }
  }
  deleteApi(url, isTokenNotExpired: any, requestType: string): Observable<any> {
    if (isTokenNotExpired) {
      let is_localhost = ENV.IS_LOCALHOST ? true : false;
      let verifyParamExist = url.includes("?") ? "&" : "?";
      return this.http.delete(`${url}${verifyParamExist}is_localhost=${is_localhost}`, this.getHttpOptions(requestType)).pipe(retry(2), catchError(errorResponse => this.handleError(errorResponse, this.router)));
    } else {
      this.router.navigate(['']);
    }
  }

  public getDealersFromApi(codePostal, dealrUrl) {
    let access_token = sessionStorage.getItem('access_token');
    let refresh_token = sessionStorage.getItem('refresh_token');
    let is_localhost = ENV.IS_LOCALHOST ? true : false;
    let url = `${ENV.API_URL}getdealers/${codePostal}`;
    const params = new HttpParams().set('dealerUrl', dealrUrl);
    let verifyParamExist = url.includes("?") ? "&" : "?";
    return this.http.get(`${url}${verifyParamExist}access_token=${access_token}&refresh_token=${refresh_token}&is_localhost=${is_localhost}`, { headers: this.getHttpOptions('normal').headers, params: params })
  }

  public getDealersFromDb() {
    return this.http.get(ENV.API_URL + "alldealers", this.getHttpOptions('normal'));
  }


  getMentionsLgals(url,requestType){
  let is_localhost = ENV.IS_LOCALHOST ? true : false;
  let verifyParamExist = url.includes("?") ? "&" : "?";
  return this.http.get(`${url}${verifyParamExist}is_localhost=${is_localhost}`, this.getHttpOptions(requestType));
  }  
  
  downloadFile(url) {
    window.open(`${url}`, "_blank");
  }



  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
 

  getExcelAffaireFile(fileName): Observable<any> {
    return this.http.get(`${ENV.API_URL}affaire_excel_file/${fileName}`, { observe: 'body', responseType: 'blob' });
  }
  


  public getAllDrs() {
    let url = `${ENV.API_URL}all_drs`;
    return this.http.get(ENV.API_URL + "all_drs", this.getHttpOptions('normal'))
  }



  public postDrsDb(drs) {    
    let url = `${ENV.API_URL}drs`;
    return this.http.post(url, drs, this.getHttpOptions('normal'))
  }

  public updateDrsDb(drs) {
    let url = `${ENV.API_URL}drs`;
    return this.http.put(url, drs, this.getHttpOptions('normal'))
  }

  handleError(errorResponse: any, router: Router) {
    console.log('errorResponse :', errorResponse)
    console.log('errorResponse :', errorResponse.status)
    switch (errorResponse.status) {
      case 400: {
        if (errorResponse.error != null && errorResponse.error.message == 'Invalid token sent') {
          this.router.navigate(['']);
          return of(404);
        } else {
          this.router.navigate(['/bad-request']);
          return of(400);
        }

      }
      case 404: {
        this.router.navigate(['/not-found']);
        return of(404);
      }
      default: {
        this.router.navigate(['/error']);
        return of(errorResponse.status);
      }
    }
  }

  async TokenValid()  {
    let now = Date.now() / 1000;
    let expires_at = JSON.parse(sessionStorage.getItem('expires_at'));
    if ((expires_at == undefined || expires_at == null) && ENV.IS_LOCALHOST === false) {
      return false;
    }
    if ( ENV.IS_LOCALHOST ===false && (now > expires_at)) {
      try {
        let refreshTokenReponse = await this.http.post(`${ENV.API_URL}/auth/refresh/token`, {}, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'authorization': sessionStorage.getItem('refresh_token')
          })
        }).toPromise() as any;
        if (refreshTokenReponse !== undefined && refreshTokenReponse !== null && refreshTokenReponse.access_token !== null) {
          sessionStorage.setItem('id_token', refreshTokenReponse.id_token);
          let profile = jwt_decode(refreshTokenReponse.id_token);
          let new_expires_at = profile['exp'];
          sessionStorage.setItem('expires_at', JSON.stringify(new_expires_at));
          return true;

        } else {
          return false;
        }
      } catch (error) {
        console.log("error :", error)
        return false;
      }
    } else {
      return true;
    }
  }
  public uploadEmails(legaleFileObj:any) {
    const headers = new HttpHeaders({ 'authorization': ENV.IS_LOCALHOST ? 'localhost' : sessionStorage.getItem('id_token') });
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    let access_token = sessionStorage.getItem('access_token');
    let refresh_token = sessionStorage.getItem('refresh_token');
    let is_localhost = ENV.IS_LOCALHOST ? true : false;
    let url = `${ENV.API_URL}v2/leads/mail/upload`;
    let verifyParamExist = url.includes("?") ? "&" : "?";
    return this.http.post(`${url}${verifyParamExist}access_token=${access_token}&refresh_token=${refresh_token}&is_localhost=${is_localhost}`, legaleFileObj, { headers: headers })
  }


  getHttpOptions(type:string) {
    if (type == 'normal') {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'authorization': ENV.IS_LOCALHOST ? 'localhost' : sessionStorage.getItem('id_token') 
        })
      }
    } else if (type === 'file') {
      return {
        headers: new HttpHeaders({
          'accept': 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': '*',
          'authorization': ENV.IS_LOCALHOST ? 'localhost' : sessionStorage.getItem('id_token') 
        })
      }
    } else if (type === 'excelFile') {
      return {
        headers: new HttpHeaders({
          'accept':'application/json',
          'authorization': ENV.IS_LOCALHOST ? 'localhost' : sessionStorage.getItem('id_token')
        })
        
      }
    } else {
      return {
        headers: new HttpHeaders({
          'accept': 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': '*',
          'responseType': 'text',
          'authorization': ENV.IS_LOCALHOST ? 'localhost' : sessionStorage.getItem('id_token') 
        })
      }
    }
  }


}




import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterTiming'
})
export class SearchTimingPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!args) {
            return value;
        }
        return value.filter((val) => {
            let rVal = (
                (val.firstName !== undefined && val.firstName !== null && val.firstName.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                || (val.lastName !== undefined && val.lastName !== null && val.lastName.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                || (val.creationDate !== null && val.creationDate !== undefined && val.creationDate.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                || (val.creationDateTempo !== null && val.creationDateTempo !== undefined && val.creationDateTempo.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                || (val.number !== undefined && val.number !== null && val.number.includes(args))
                || (val.status !== null && val.status !== undefined && val.status.includes(args))
            );
            return rVal;
        })

    }

}
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ToastrService } from 'ngx-toastr';
import { ENV } from '../../core/env.config';
import Swal from 'ngx-angular8-sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
declare var $: any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class FormsComponent implements OnInit, AfterViewInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  models: any = [];
  event: any;
  selectedArray: any[];
  selectedOption: string = "utiliser une formulaire";
  codeSecret: string = "";
  p: number = 1;
  total: number;
  searchTerm: string = '';
  codeSecretToReinitise: string = '';
  isCodeSecretLoading: boolean = false;
  isLoading: boolean = false;
  field: string = 'date_creation';
  order: String = 'DESC';
  eventToduplicate: any;
  reseauEventId: string = '';
  reseauEventName: string = "";
  is_dealer_event_for_duplicate: boolean = false;
  reseau: any = [];

  constructor(private cdr: ChangeDetectorRef, private datePipe: DatePipe, private datacatchingService: DatacatchingService, private toastr: ToastrService, private router: Router) {

  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  pageChanged(pageNum) {
    this.p = pageNum;
    this.modifyEvents(pageNum, this.field, this.order);
  }
  clearFilter() {
    this.searchTerm = '';
    this.modifyEvents(1, this.field, this.order);
  }
  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyEvents(this.p, this.field, this.order);

  }

  filterEvents() {
    this.modifyEvents(1, this.field, this.order);
  }
  async modifyEvents(page, field, order) {
    this.isLoading = true;
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/events?page=${page}&field=${field}&order=${order}&size=8&is_dealer_event=false`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/events?page=${page}&size=8&field=${field}&order=${order}&term=${this.searchTerm}&is_dealer_event=false`;
    }
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(models => {
      console.log('events', models['events']);
      this.models = models['events'];
      this.total = models['totalItems']
      this.p = models['currentPage'];
      if (this.models && this.models.length > 0) {
        let i = 1;
        for (let model of this.models) {
          model['number'] = JSON.stringify((page - 1) * 8 + i);
          i++;
        }
      }
      this.isLoading = false;

    })

  }

  ngOnInit(): void {
    this.modifyEvents(1, this.field, this.order);
    this.dropdownList = [
      { id: 1, reseau: 'R1' },
      { id: 2, reseau: 'R2' }

    ];
    this.selectedItems = []
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'reseau',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      closeDropDownOnSelection: true
    };
  }
  async ngAfterViewInit() {
  }
  onSelectNetworkEvent(code_event, eventName) {
    this.reseauEventId = code_event;
    this.reseauEventName = eventName;
  }
  close() {
    this.selectedItems = [];
    this.reseauEventId = '';
    this.reseauEventName = '';
  }

  async updateReseau() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    console.log(this.selectedItems);
    let obj = { reseau: this.selectedItems, eventId: this.reseauEventId };
    this.datacatchingService.postApi(`${ENV.API_URL}v2/event/reseau`, obj, isTokenNotExpired, 'normal').subscribe(res => {
      this.modifyEvents(this.p, this.field, this.order);
      this.close();
      this.toastr.success("Paramètres modifiés avec succès !");
    })
  }
  onItemDeSelect($event) {
    console.log(this.selectedItems);
  }

  onEditEventReseau(code_event, reseau, eventName) {
    this.reseauEventId = code_event;
    this.selectedItems = reseau;
    this.reseauEventName = eventName;
  }


  onSelect(id) {
    sessionStorage.setItem('id', JSON.stringify(id));
    this.datacatchingService.settoggleMenu('toggled');
  }

  copyInputMessage(textToCopy) {
    const create_copy = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', textToCopy);
      e.preventDefault();
    };
    document.addEventListener('copy', create_copy);
    document.execCommand('copy');
    document.removeEventListener('copy', create_copy);

    this.toastr.success("Le code secret est copié")
  }

  onEdit(id) {
    sessionStorage.setItem('id', JSON.stringify(id));
    $('.modal-backdrop').remove();
    $('.modal-backdrop').removeClass('show');
    this.router.navigate(['/events']);
  }
  async onDelete(id, eventName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let user = JSON.parse(sessionStorage.getItem("currentUser"))
    let userObj = { date_creation: this.transfromDateHistorique(new Date().toLocaleString()), event_name: eventName, action: "Suppression de l'événement", ...user }
    console.log("actionObj", userObj);
    this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {

      console.log("action recorded")
    });
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: `Vous ne pourrez pas récupérer ${eventName} événement !`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimez-le!',
      cancelButtonText: 'Non, garde-le'
    }).then((result) => {
      if (result.value) {
        let url = `${ENV.API_URL}v2/event/delete/${id}`;
        this.datacatchingService.deleteApi(url, isTokenNotExpired, 'normal').subscribe(res => {
          if (res['success']) {
            if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
            this.models = this.models.filter(model => model.event_id !== id);
            this.modifyEvents(this.p, this.field, this.order)
            this.cdr.detectChanges();
            this.toastr.success(res['message']);
          } else {
            this.toastr.error(res['message']);
          }
        });
        // this.getEvents();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Annulé',
          `Le <strong> ${eventName} </strong> événement a est sûr:)`,
          'error'
        )
      }
    })
  }
  transfromDate(date) {

    return this.datePipe.transform(date, 'shortDate', '', 'fr-FR');
  }
  transfromDateHistorique(date) {
    date = date.split('/').join('-').replace(',', '');
    let onlyDate = date.split(" ");
    let formatChangedDate = onlyDate[0].split("-");
    let d = `${formatChangedDate[2]}-${formatChangedDate[1]}-${formatChangedDate[0]}T${onlyDate[1]}`;
    console.log('date', d)
    return d;
  }

  showCode(id) {
    this.codeSecret = id;
  }

  ReIntilizeCode(event_id) {
    this.isCodeSecretLoading = false;
    this.codeSecretToReinitise = event_id;
  }

  async onPalyPaused(id, isPaused, event_name) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let user = JSON.parse(sessionStorage.getItem("currentUser"))
    let userObj = { date_creation: this.transfromDateHistorique(new Date().toLocaleString()), event_name: event_name, action: isPaused ? "Pause" : "Play", ...user }
    this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
      console.log("action recorded");
    });
    let url = `${ENV.API_URL}v2/event/status`;
    let payLoad = { eventId: id, isPaused: isPaused };
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {
      console.log('res', res);
      if (res['success']) {
        if (isPaused) {
          this.toastr.success("L'evenement est mise en statut non actif");
        } else {
          this.toastr.success("L'evenement est mise en statut actif");
        }
        for (let event of this.models) {
          if (event.code_event == id) {
            event.is_paused = isPaused;
          }
        }
      }
    });
  }

  async onNetworkEventChanged(id, is_dealer_event, event_name) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let user = JSON.parse(sessionStorage.getItem("currentUser"))
    let userObj = { date_creation: this.transfromDateHistorique(new Date().toLocaleString()), event_name: event_name, action: !is_dealer_event ? "Dealer event" : "Not Dealer event", ...user }
    this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
      console.log("action recorded");
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
    });
    let url = `${ENV.API_URL}v2/event/toggle_dealer_event`;
    let payLoad = { eventId: id, is_dealer_event: !is_dealer_event };
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {
      console.log('res', res);
      if (res['success']) {
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        if (is_dealer_event) {
          this.toastr.success("Le statut okta modifié");
        } else {
          this.toastr.success("Le statut okta modifié");
        }
        for (let event of this.models) {
          if (event.code_event == id) {
            event.is_dealer_event = !is_dealer_event;
          }
        }
      }
    });
  }
  async updateCodeSecret(codeSecretToReinit) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isCodeSecretLoading = false;
    let payLoad = { codeSecret: codeSecretToReinit };
    let url = `${ENV.API_URL}v2/event/codesecret`;
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {

      this.codeSecretToReinitise = res['newCodeSecret'];
      this.toastr.success("Le code secret est bien modifié")
      for (let model of this.models) {
        if (model.code_event == codeSecretToReinit) {
          model.code_event = res['newCodeSecret'];
          let user = JSON.parse(sessionStorage.getItem("currentUser"))
          let userObj = { date_creation: this.transfromDateHistorique(new Date().toLocaleString()), event_name: model.event_name, action: "Réinitialisation code secret", ...user }
          this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
            console.log("action recorded");

          });
        }
      }
      this.isCodeSecretLoading = true;
    })
  }
  goToEvent(second_code) {
    window.open(`${ENV.PWA_URL}?code=${second_code}`, "_blank");
  }

  duplicateEvent(event) {
    console.log('event', event);
    this.is_dealer_event_for_duplicate = event.is_dealer_event;
    this.reseau = event.reseau;
    this.eventToduplicate = { ...event };
    this.eventToduplicate.event_obj.name = `${this.eventToduplicate.event_obj.name} copie`
  }

  async createDuplicateEvent() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let cts = new Date().toISOString();
    let url = `${ENV.API_URL}/v2/event/create`;
    this.datacatchingService.postApi(url, { event_obj: this.eventToduplicate.event_obj, cts: cts, is_dealer_event: this.is_dealer_event_for_duplicate, reseau: this.reseau }, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['status'] === 200) {
        this.toastr.success("Événement créé avec succès :)", '', {
          timeOut: 5000
        });
        this.modifyEvents(1, this.field, this.order)
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ENV } from 'src/app/core/env.config';

declare var $: any;
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})

export class BaseComponent implements OnInit {

  constructor(private datacatchingService: DatacatchingService, private toastr: ToastrService, private router: Router) { }
  public models: any;
  public events: any;
  public selectedArray: any = [];
  public tempDrs: any = [];
  public drsChangedEvents: any = [];
  public drsFromApi: string[] = [];
  public eventsToModifyList: any = [];

  ngOnInit() {
    if (ENV.IS_LOCALHOST) {
      sessionStorage.setItem('currentUser', JSON.stringify({
        "uid": "p103248",
        "firstName": "srikanth",
        "lastName": "karra",
        "email": "srikanth@gmail.com"
      }))
    }  
  }
}

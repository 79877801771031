<div class="app-conent content mt-5 pt-3 mb-2">
    <div class="row text-left pl-2 pr-2">
        <div class="col text-left pl-0 pr-0 ml-1">
            <a class="mb-1 home-link" routerLink="/home" style="color:#1f2532">
                <i class="fa fa-arrow-left" style='color:#777;padding-right:10px;'></i>Retour à l'accueil
            </a>
        </div>
        <div class="col-5 text-right pl-0 pr-0 mr-1 d-flex">
            <p class="remove-filter" (click)="clearFilter()" *ngIf="searchTerm.length"><i class="la la-remove"></i> </p>
            <input type="text" placeholder="sélectionner la plage de dates" class="form-control border-0"
                bsDaterangepicker (bsValueChange)="onValueChange($event)" [(ngModel)]="bsRangeValue"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default',showClearButton: true, clearPosition: 'top',showWeekNumbers: false,showPreviousMonth: true }">
            <i title="Effacer" *ngIf="bsRangeValue" style=" margin-left: -20px;margin-top: 13px;cursor: pointer;"
                (click)="clearDateRange()" class="la la-remove"></i>
            <input type="text" class="form-control border-0 rounded-0 ml-1" [(ngModel)]="searchTerm"
                placeholder="Nom,Prénom,Email,Phone,lead id ..." />
            <button class="btn btn-primary-r box-shadow-0" (click)="filterEvents()">Recherche</button>
        </div>
    </div>
    <div class="table-wrapper m-1 mb-2">
        <table class="table table-striped mb-0">
            <thead>
                <tr>
                    <th scope="col" style="padding: 0.6rem 1.5rem !important;"> <input type="checkbox" name="all"
                            id="cbx" (change)="checkAllDelete($event)" /> </th>
                    <th scope="col">Lead Submission Id </th>
                    <th>Sfdc Erreur Id</th>
                    <th style="cursor:pointer" scope="col"
                        (click)="field==='date_creation' && order==='ASC' ? sortBy('date_creation','DESC') : sortBy('date_creation','ASC')">
                        Date de création
                        <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='date_creation' && order==='ASC'"></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                            [class.text-dark]="field==='date_creation' && order==='DESC'"></i>

                    </th>
                    <th style="cursor:pointer" scope="col" scope="col"
                        (click)="field==='event_name' && order==='ASC' ? sortBy('event_name','DESC') : sortBy('event_name','ASC')">
                        Nom de l'événement
                        <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='event_name' && order==='ASC'"></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                            [class.text-dark]="field==='event_name' && order==='DESC'"></i>
                    </th>
                    <th>Status code</th>
                    <th>Message d'erreur</th>
                    <th>Indispo de l’API <span *ngIf="isExistError403"><input type="checkbox" name="all"
                                [checked]="isAllChecked()" (change)="checkAll($event)" /></span> </th>
                    <th>Actions</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    *ngFor="let lead of listLeads | paginate: { itemsPerPage: itemsPerPage, currentPage: p,totalItems: total };let i=index;">
                    <td style="padding: 0.6rem 1.5rem !important;"> <input type="checkbox" name="all"
                            [checked]="lead.state" (change)="checkLeadDelete($event, lead.id)" /></td>
                    <td>
                        {{(lead.id.length>10)? (lead.id | slice:0:15)+'...':(lead.id)}}
                        <i class="far fa-copy" id="copy-icon" title="copier le Id"
                            (click)="copyInputMessage(lead.id)"></i>
                    </td>
                    <td>
                        <span *ngIf="lead?.sfdcErrorId!==undefined && lead?.sfdcErrorId!==null ">
                            {{lead.sfdcErrorId}}</span>
                    </td>
                    <td>
                        {{lead.creationDate}}
                    </td>
                    <ng-template #tipContent> {{lead.event_name}}</ng-template>
                    <td container="body" [ngbTooltip]="lead.event_name?.length>25 ? tipContent : ''">
                        {{(lead.event_name?.length>25)? (lead.event_name | slice:0:15)+'...':(lead.event_name)}}
                    </td>
                    <td>{{lead.status_code}}</td>
                    <td>
                        <ng-template #tipContent2>Voir le message d'erreur</ng-template>
                        <button type="button" class="btn btn-danger btn-sm" [ngbTooltip]="tipContent2"
                            (click)="openModalError(errorMessageTemplate,lead.id_db)">
                            <i class="fas fa-eye"></i>
                        </button>
                    </td>
                    <td>
                        <span *ngIf="lead.status_code==503 || lead.status_code==403">
                            Oui
                            <ng-template #tipContentLead>Envoyer Lead</ng-template>
                            <button type="button" class="btn btn-primary btn-sm mr-1" [ngbTooltip]="tipContentLead"
                                (click)="sendLead(lead)">
                                <i class="fas fa-paper-plane"></i>
                            </button>
                            <input type="checkbox" readonly name="checkbox" value="{{lead.id_db}}"
                                [(ngModel)]="lead.state" />
                        </span>
                        <span *ngIf="lead.status_code!==503 || lead.status_code!==403">
                            Non
                        </span>

                    </td>
                    <td>
                        <ng-template #tipContent1>Voir plus d'info du Lead</ng-template>
                        <button type="button" class="btn btn-success btn-sm mr-1" [ngbTooltip]="tipContent1"
                            (click)="openModalEdit(templateInfo,lead.id_db)">
                            <i class="fas fa-eye"></i>
                        </button>
                        <ng-template #tipContentEdit>Modifier l'info du Lead</ng-template>
                        <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit"
                            (click)="openModalEdit(templateEdit,lead.id_db)">
                            <i class="fas fa-pen"></i>
                        </button>
                        <ng-template #tipContentDelete>Suprrimer le Lead</ng-template>
                        <button type="button" class="btn btn-danger btn-sm mr-1" [ngbTooltip]="tipContentDelete"
                            (click)="onDeleteLead(templateDelete,lead.id_db)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <button class="btn valider ml-1" *ngIf="isAllLeadsCheckedDelete || isMoreThanOneChecked"
        style="padding: 0.4rem 1rem !important;" (click)="DeleteAllLeads()">Supprimer
        tous</button>
    <div class="text-right w-100">
        <button class="btn btn-secondary mr-1 box-shadow-1" *ngIf="isAllLeadsChecked" (click)="sendAllLeads()">Envoyer
            tous</button>
    </div>
    <div *ngIf="!listLeads.length && !isLoading" class='text-center p-4'>
        <h5 class="text-center">Il n'existe pas les leads</h5>
    </div>
    <div class="text-center mt-0" *ngIf="listLeads.length && !isLoading">
        <div class="mt-0">
            <pagination-controls responsive="true" previousLabel="" nextLabel="" (pageChange)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>
<ng-template #templateEdit>
    <div class="modal-header bg-primary border-0 text-white text-center bg-warning">
        <h4 class="modal-title text-white ml-auto text-center">Modifier l'information du lead</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
        <div>
            <tabset>
                <tab heading="Lead Objet" id="tab1">
                    <pre contenteditable="true" spellcheck="false" class="p-1 font-size-base mt-2" style="font-family: unset;" #obj >{{leadObj.lead_obj | json }}</pre>
                        <div class="d-flex justify-content-between  mt-3">
                            <div>
                                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()">Annuler</button>
                            </div>
                            <div>
                                <button class="btn btn-secondary valider" (click)="onSubmitLeadObj(obj.innerHTML)">Valider</button>
                            </div>
                        </div>
                </tab>
                <tab heading="Lead Formulaire">
                    <form [formGroup]="userForm" class="m-2">
                        <h5> <i class="fas fa-user"></i> Client Info</h5>
                        <hr class="mb-0">
                        <div class="row m-1">
                            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                                [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || ( !data.parentKey.includes('client') && data.parentKey!=='dealer') }">
                                <label>{{data.label}}</label>
                                <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                                    [ngModel]="decodeValue(data.value)" value="{{ decodeValue(data.value) }}" />
                            </div>
                        </div>
                        <h5 class="mt-2"> <i class="fas fa-info"></i> Lead Info </h5>
                        <hr class="mb-0">
                        <div class="row m-1">
                            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                                [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='leadInfo' }">
                                <label>{{data.label}}</label>
                                <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                                    [ngModel]="decodeValue(data.value)" value="{{decodeValue(data.value)}}" />
                            </div>
                        </div>
                        <h5 class="mt-2" [ngClass]="{'hidden':checkKey('vehicle')}"> <i class="fas fa-car"></i> Véhicule
                            Info </h5>
                        <hr class="mb-0" [ngClass]="{'hidden':checkKey('vehicle')}">
                        <div class="row m-1">
                            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                                [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='vehicle' }">
                                <label>{{data.label}}</label>
                                <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                                    [ngModel]="decodeValue(data.value)" value="{{decodeValue(data.value)}}" />
                            </div>
                        </div>

                        <h5 class="mt-2" [ngClass]="{'hidden':checkKey('ownedVehicle')}"> <i class="fas fa-car"></i>
                            Véhicule posséder Info
                        </h5>
                        <hr class="mb-0" [ngClass]="{'hidden':checkKey('ownedVehicle')}">
                        <div class="row m-1">
                            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                                [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='ownedVehicle' }">
                                <label>{{data.label}}</label>
                                <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                                    [ngModel]="decodeValue(data.value)" value="{{decodeValue(data.value)}}" />
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-between ml-3 mr-3 mt-3">
                        <div>
                            <button class="btn btn-light text-dark border-0" aria-label="Close"
                                (click)="modalRef?.hide()">Annuler</button>
                        </div>
                        <div>
                            <button class="btn btn-secondary valider" (click)="onSubmit()">Valider</button>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</ng-template>

<ng-template #templateInfo>
    <div class="modal-header border-0 text-white text-center bg-success">
        <h4 class="modal-title text-white ml-auto text-center">Les détails du leads</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
        <form [formGroup]="userForm" (submit)="onSubmit()">
            <h5> <i class="fas fa-user"></i> Client Info</h5>
            <hr class="mb-0">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden':  data.parentKey!=='client' && data.parentKey!=='dealer' &&  !data.parentKey.includes('client') }">
                    <p> <span class="font-weight-normal ">{{data.label}}</span> <br> <strong
                            class="font-weight-500">{{decodeValue(data.value)}}</strong></p>
                </div>
            </div>
            <h5 class="mt-2"> <i class="fas fa-info"></i> Lead Info </h5>
            <hr class="mb-0">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden': data.parentKey!=='leadInfo' }">
                    <p *ngIf="data.label!=='leadSubmissionTimestamp'"> <span
                            class="font-weight-normal">{{data.label}}</span> <br> <strong
                            class="font-weight-500">{{data.value}}</strong></p>
                    <p *ngIf="data.label==='leadSubmissionTimestamp'"> <span
                            class="font-weight-normal">{{data.label}}</span> <br>
                        <strong class="font-weight-500">{{data.value | date:'medium'}}</strong>
                    </p>
                </div>
            </div>
            <h5 class="mt-2" [ngClass]="{'hidden':checkKey('vehicle')}"> <i class="fas fa-car"></i> Véhicule Info </h5>
            <hr class="mb-0" [ngClass]="{'hidden':checkKey('vehicle')}">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden': data.parentKey!=='vehicle' }">
                    <p> <span class="font-weight-normal">{{data.label}}</span> <br> <strong
                            class="font-weight-500">{{data.value}}</strong></p>
                </div>
            </div>

            <h5 class="mt-2" [ngClass]="{'hidden':checkKey('ownedVehicle')}"> <i class="fas fa-car"></i> Véhicule
                posséder Info </h5>
            <hr class="mb-0" [ngClass]="{'hidden':checkKey('ownedVehicle')}">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden': data.parentKey!=='ownedVehicle' }">
                    <p> <span class="font-weight-normal">{{data.label}}</span> <br> <strong
                            class="font-weight-500">{{data.value}}</strong></p>
                </div>
            </div>
        </form>


    </div>
</ng-template>
<div *ngIf="isSendLead">

    <div class="loadContainer">
        <div class="loading-wrapper">
            <div class="loader"></div>
        </div>
    </div>
</div>


<ng-template #errorMessageTemplate>
    <div class="modal-header bg-primary border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Le Message erreur du lead</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-1 error-msg-body">
        <div>Status Code : <strong>{{statusCode}}</strong> <br>
            <p>Message: <span>{{decodeValue(errorMessage)}}</span></p>
            <p class="mt-1">Request body:</p>
            <pre class="p-1 font-size-base"
                style="font-family: unset;">{{decodeValueError(leadObj.lead_obj) | json }}</pre>
        </div>
    </div>
    <div class="modal-footer" style="justify-content: center;padding: 5px 0;">
        <button type="button" class="btn btn-secondary" aria-label="Close" (click)="modalRef?.hide()">Fermer</button>
    </div>
</ng-template>
<ng-template #templateDelete>
    <div class="modal-header border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Supprimer le lead</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2  edit-lead-modal-body">
        <p class="text-center">Voulez-vous supprimer le Lead?</p>
        <div class="d-flex justify-content-between">

            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                    style="padding: 0.4rem 1rem !important;">Non</button>
            </div>
            <div>
                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
            </div>
        </div>
    </div>
</ng-template>

<div class="text-center" *ngIf="isLoading">
    <h5 class="text-center">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </h5>
</div>
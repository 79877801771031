<div class="app-conent content mt-5 pt-3">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="row pl-1 pr-0 mb-2">
            <div class="col text-left pl-0 pr-0 mb-2">
                <a class="mb-1 home-link" style="color:#1f2532" routerLink="/home">
                    <i class="fa fa-arrow-left" style='color:#777;padding-right:10px;'></i>Retour à la accueil</a>
            </div>
                <div class="col-4 text-right pl-0 pr-0 mr-1 d-flex">
                    <p class="remove-filter" (click)="clearFilter()" *ngIf="searchTerm.length"><i class="la la-remove"></i> </p>
                    <input  type="text rounded-0" class="form-control border-0" [(ngModel)]="searchTerm"  placeholder="saisir le nom d'événement..." /> 
                    <button class="btn btn-primary box-shadow-0" (click)="filterEvents()">Recherche</button>
                </div>
           </div>
            <div class="table-wrapper mb-1" *ngIf="models">
            <table class="table table-striped mb-0" >
                <thead>
                    <tr>
                        <th style="cursor:pointer" scope="col"
                            (click)="field==='date_creation' && order==='ASC' ? sortBy('date_creation','DESC') : sortBy('date_creation','ASC')">
                            Date de création
                            <i style="cursor:pointer;padding-right:0" class="fas fa-arrow-down font-size-xsmall text-light"
                                [class.text-dark]="field==='date_creation' && order==='ASC'"></i>
                            <i style="padding-left: 2px; cursor:pointer ;padding-right:0" class="fas fa-arrow-up font-size-xsmall text-light"
                                 [class.text-dark]="field==='date_creation' && order==='DESC'"></i>
                        </th>
                            <th style="cursor:pointer" scope="col" scope="col"
                                (click)="field==='event_name' && order==='ASC' ? sortBy('event_name','DESC') : sortBy('event_name','ASC')">
                                Nom de l'événement
                                <i style="cursor:pointer;padding-right:0" class="fas fa-arrow-down font-size-xsmall text-light"
                                    [class.text-dark]="field==='event_name' && order==='ASC'" ></i>
                                <i style="padding-left: 2px; cursor:pointer;padding-right:0" class="fas fa-arrow-up font-size-xsmall text-light"
                                     [class.text-dark]="field==='event_name' && order==='DESC'"></i>
                            </th>
                        <!-- <th scope="col">Visualiser</th> -->
                        <th scope="col">Modifier</th>
                        <th scope="col">Code</th>
                        <th scope="col">Lien</th>
                        <th scope="col">Dupliquer</th>
                        <th scope="col">Actif</th>
                        <th scope="col">Okta</th>
                        <th>Reseau</th>
                        <th scope="col">Réinitialiser code</th>
                        <th scope="col">Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let model of models | paginate: { itemsPerPage: 8, currentPage: p, totalItems: total };let i=index;">
                        <td style='color: #1f2532;'>{{ transfromDate(model.date_creation)}}</td>
                        <ng-template #tipContent> {{model.event_name}}</ng-template>
                        <td style='color: #1f2532;'  tooltipClass="tooltip-custom-class" container="body" [ngbTooltip]="model.event_name?.length>35 ? tipContent : ''">{{(model.event_name?.length>35)? (model.event_name | slice:0:35)+'...':(model.event_name)}}
                        </td>
                        <td>
                            <div class="link-icon" (click)="onEdit(model.code_event)">
                                <span class="text-warning"><i class="la la-pencil"></i></span>
                            </div>
                        </td>
                        <td>
                            <div class="link-icon" (click)="showCode(model.code_event)" data-toggle="modal" data-target="#exampleModal">
                                <span class="text-secondary"><i class="la la-user-secret"></i></span>
                            </div>
                        </td>
                        <td>
                            <div class="link-icon" (click)="goToEvent(model.second_code)">
                                <span class="text-info"><i class="la la-link"></i></span>
                            </div>
                        </td>
                        <td>
                            <div class="link-copy" (click)="duplicateEvent(model)" data-toggle="modal" data-target="#eventCopyModal">
                                <span class="text-secondary"><i class="la la-copy"></i></span>
                            </div>
                        </td>
                        <td>
                            <div class="link-icon">
                                <label class="switch">
                                    <input type="checkbox"  [checked]="!model.is_paused" (change)='onPalyPaused(model.code_event,$event.target.checked? false:true,model.event_name)'>
                                    <span class="slider round">
                                        <label *ngIf='!model.is_paused'class="pr-2 text-white">Oui</label>
                                        <label  *ngIf='model.is_paused' class="pl-2 text-white">Non </label>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="link-icon">
                                <label class="switch">
                                    <input type="checkbox" [checked]="model.is_dealer_event"
                                        (change)='onNetworkEventChanged(model.code_event,$event.target.checked? false:true,model.event_name)'>
                                    <span class="slider round okta">
                                        <label *ngIf='model.is_dealer_event' class="pr-2 text-white">Oui</label>
                                        <label *ngIf='!model.is_dealer_event' class="pl-2 text-white">Non </label>
                                    </span>
                                </label>
                            </div>
                        </td><td> 
                            <span *ngIf="model.is_dealer_event">
                                <div *ngIf="model?.reseau!==null && model?.reseau.length>0" class="d-flex">
                                    <div>
                                    <div *ngIf="model?.reseau.length===1">
                                        <span style="padding-right: 2px;">{{model?.reseau[0]?.reseau}}</span>
                                    </div>
                                    <div *ngIf="model?.reseau.length===2">
                                        <div style="padding-right: 2px;">{{model?.reseau[0]?.reseau}}{{"+" +model?.reseau[1]?.reseau}} </div>
                                    </div>
                                    </div>
                                    <div style="padding-left: 5px;">
                                        <i class="la la-pencil" tooltipClass="tooltip-custom-class"  placement="bottom" ngbTooltip="Modifier reseau" (click)="onEditEventReseau(model.code_event, model.reseau, model.event_name)"
                                            data-toggle="modal" data-target="#exampleModalCenter"></i>
                                    </div>
                                </div>
                                <span *ngIf="model.reseau===null || !model?.reseau.length">
                                    <i class="la la-plus"  tooltipClass="tooltip-custom-class" placement="bottom" ngbTooltip="Ajouter reseau" (click)="onSelectNetworkEvent(model.code_event,model.event_name)" data-toggle="modal" data-target="#exampleModalCenter"></i>
                                </span>
                            </span>
                        </td>
                        <td>
                            <div class="link-icon" (click)="ReIntilizeCode(model.code_event)" data-toggle="modal" data-target="#exampleModal1">
                                <span class="text-secondary"><i class="las la-sync-alt"></i></span>
                            </div>
                        </td>
                        <td>
                            <div class="link-icon"(click)="onDelete(model.code_event,model.event_obj.name)">
                                <span class="text-danger">
                                    <i class="la la-trash"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <p *ngIf="!models" class="text-center pt-5">Votre recherche n'a retourné aucun résultat. Veuillez modifier les critères de recherche.</p>
                <div class="text-center mt-0" *ngIf="models && !isLoading">
                    <div class="mt-0">
                        <pagination-controls responsive="true" previousLabel="" nextLabel="" (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>
            <div class="modal bd-example-modal-sm " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content col-5" style="margin: 0 auto !important;">
                        <div class="modal-header border-bottom-0">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <h5 class="text-center mb-2">
                            Le code secret de l'évènement est
                        </h5>
                        <h5 class="text-center"><strong class="text-center">{{codeSecret}} <i class='far fa-copy' id='copy-icon' title='copier le Id'
                                                    (click)='copyInputMessage(codeSecret)'></i> </strong> </h5>
                        <div class="modal-footer mt-2" style="justify-content: center !important;">
                            <button type="button" class="btn btn-outline-dark" data-dismiss="modal" style="padding: .375rem .75rem !important; ">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal bd-example-modal-sm " id="eventCopyModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content col-5 p-0" style="margin: 0 auto !important;">
                        <div class="modal-header border-bottom-0 w-100 p-1 m-0 bg-info">
                          <h5 class="modal-title text-white text-center ml-auto" id="exampleModalLabel">Dupliquer l'événement</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="p-1">
                        <label for="titre">Nouveau titre <sup style='color:#000'>*</sup></label>
                        <input class="form-control mb-2" type="text" id="titre" [(ngModel)]="eventToduplicate?.event_obj.name" />
                        </div>
                        <div class="modal-footer mt-2" style="justify-content: center !important;">
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                style="padding: .375rem .75rem !important; " (click)="createDuplicateEvent()">créér</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal bd-example-modal-sm " id="exampleModal1" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content col-5" style="margin: 0 auto !important;">
                        <div class="modal-header border-bottom-0">
                           
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                         <h5 class="text-center mb-2" *ngIf='!isCodeSecretLoading'>
                          Voulez vous vraiment réinitilser le code secret ?
                         </h5>
                         <h5 class="text-center mb-2" *ngIf='isCodeSecretLoading'>
                             Le nouveau code secret est 
                         </h5>
                         <h5 class="text-center"><strong class="text-center">{{codeSecretToReinitise}} <span *ngIf='isCodeSecretLoading'><i class='far fa-copy' id='copy-icon' title='copier le Id'
                                                        (click)='copyInputMessage(codeSecretToReinitise)'></i></span>   </strong></h5>
                        <div class="modal-footer mt-2" style="justify-content: space-between;" *ngIf='!isCodeSecretLoading'>
                            <button type="button" class="btn btn-outline-dark" style="padding: .375rem .75rem !important; " data-dismiss="modal">Non</button>
                            <button type="button" class="btn btn-primary" style="padding: .375rem .75rem !important;" (click)='updateCodeSecret(codeSecretToReinitise)'>Oui</button>
                        </div>
                        <div class="modal-footer mt-2" style="justify-content: center !important;" *ngIf='isCodeSecretLoading'>
                            <button type="button" class="btn btn-outline-dark" data-dismiss="modal"
                                style="padding: .375rem .75rem !important; ">Fermer</button>
                        </div>

                    </div>
                </div>
            </div>
            <h5 *ngIf="models && models.length == 0 && !isLoading" class="text-center">Il n'existe aucun événement pour l'instant!</h5>
    </div>
</div>
        <div class="text-center" *ngIf="isLoading">
            <h5 class="text-center">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h5>
        </div>
<!-- Modal -->
<div class="modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div  class="modal-content col-5" style="margin: 0 auto !important;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Sélectionner Reseau</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <p class="mb-1">Nom de l'événement: <span><strong>{{reseauEventName}}</strong> </span></p>
                <ng-multiselect-dropdown [placeholder]="'Sélectionner'"  [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Fermer</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateReseau()">Enregistrer</button>
            </div>
        </div>
    </div>
</div>
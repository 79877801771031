

<div class="app-conent content mt-5 pt-3 mb-2">
    <div class="w-25 ml-auto mb-1 text-right">
    <button type="button" class="btn btn-primary btn-primary-r mr-1" (click)="onAddcode(templateAdd,'add', null)"><i class="fas fa-plus" style="padding-right: 5px;"></i> Ajouter un nouveau</button>
    </div>
    <div class="table-wrapper m-1 mb-2">

 
   
<table class="table bg-white table-striped">
    <thead>
        <tr>
            <th scope="col">N°</th>
            <th scope="col">Nom</th>
            <th scope="col">Code</th>
           <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let country of countries;let i=index">
            <td>{{i+1}}</td>
            <td>{{country.name}}</td>
            <td>{{country.code}}</td>
            <td><ng-template #tipContentEdit>Modifier pays</ng-template>
            <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit" (click)="onAddcode(templateAdd, 'edit',country.id)">
                <i class="fas fa-pen"></i>
            </button>
            <ng-template #tipContentDelete>Suprrimer pays</ng-template>
            <button type="button" class="btn btn-danger btn-sm mr-1" (click)="onDeletecode(templateDelete,country.id)" [ngbTooltip]="tipContentDelete">
                <i class="fas fa-trash"></i>
            </button></td>
        </tr>
    </tbody>
</table>
</div>
<ng-template #templateAdd>
    <div class="modal-header border-0 text-white text-center bg-white p-0 pr-1 pt-1 pb-1 btn-light">
        <h4 class="modal-title text-white ml-auto text-center text-dark ">Ajouter un pays</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden" style="color: black !important">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
    <div>
        <form class="mt-2" [formGroup]="countryForm">
            <div class="form-group text-left mb-0">
                <label for="name">Nom <span class="star">*</span></label>
                <input type="name" spellcheck="false"
                    [ngClass]="{ 'error':fields.name.touched && fields.name.invalid ,'success-class':fields.name.touched && fields.name.valid }"
                    id="name" formControlName="name" class="form-control" name="name" required>
            </div>
            
            <div *ngIf="fields.name.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.name.touched && fields.name.errors?.required" class="error-msg">Le nom obligatoire</span>
                    <span
                        *ngIf="(fields.name.touched && fields.name.invalid) || (fields.name.touched && fields.name.errors?.pattern) "
                        class="error-msg">
                        Entrer un nom valide
                    </span>
                </p>
            </div>
            <!-- code -->
            <div class="form-group text-left mb-0">
                <label for="code">code <span class="star">*</span></label>
                <input type="code" spellcheck="false"
                    [ngClass]="{ 'error':fields.code.touched && fields.code.invalid ,'success-class':fields.code.touched && fields.code.valid }"
                    id="code" formControlName="code" appMaxLength class="form-control" name="code" required>
            </div>
            
            <div *ngIf="fields.code.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.code.touched && fields.code.errors?.required" class="error-msg">L'adresse code
                        obligatoire</span>
                    <span
                        *ngIf="(fields.code.touched && fields.code.invalid) || (fields.code.touched && fields.code.errors?.pattern) "
                        class="error-msg">
                        Entrer un code valide
                    </span>
                    <span *ngIf="fields.code.errors?.minlength && fields.code.touched"
                class="error-msg">
                <span>veuillez saisir minimum 2 caracteres</span>
                    </span>
                </p>

                
            
            
            </div>
            <div class="d-flex justify-content-between mt-3">
                <div>
                    <button type="button" class="btn btn-light text-secondary border-0 rounded-0" (click)="onCancel()"> <i class="fas fa-times" style="padding-right: 5px;"></i>Annuler</button>
                </div>
                <div> 
                <button  *ngIf="!isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"  [disabled]="!countryForm.valid" (click)="onSubmitSinglecode()"> <i class="fas fa-save" style="padding-right: 5px;"></i>Enregistrer</button>
                <button *ngIf="isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"  [disabled]="!countryForm.valid" (click)="onUpdateSinglecode()"> <i class="fas fa-save"  style="padding-right: 5px;"></i>Mis à jour</button>
                </div>
            </div>
    </form>
    </div>
    </div>
</ng-template>
<ng-template #templateDelete>
    <div class="modal-header border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Supprimer le pays</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2  edit-lead-modal-body">
        <p class="text-center">Voulez-vous supprimer le pays?</p>
        <div class="d-flex justify-content-between">

            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                    style="padding: 0.4rem 1rem !important;">Non</button>
            </div>
            <div>
                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
            </div>
        </div>
    </div>
</ng-template>

</div>



<div class="app-conent content mt-5 pt-3 mb-2">
    <div class="w-25 ml-auto mb-1 text-right">
    <button type="button" class="btn btn-primary btn-primary-r mr-1" (click)="onAddEmail(templateAdd,'add', null)"><i class="fas fa-plus" style="padding-right: 5px;"></i> Ajouter un nouveau</button>
    </div>
    <div class="table-wrapper m-1 mb-2">

 
   
<table class="table bg-white table-striped">
    <thead>
        <tr>
            <th scope="col">N°</th>
            <th scope="col">Email</th>
           <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let email of  emailsList;let i=index">
            <td>{{i+1}}</td>
            <td>{{email.email}}</td>
            <td><ng-template #tipContentEdit>Modifier l'email</ng-template>
            <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit" (click)="onAddEmail(templateAdd, 'edit',email.id)">
                <i class="fas fa-pen"></i>
            </button>
            <ng-template #tipContentDelete>Suprrimer l'email</ng-template>
            <button type="button" class="btn btn-danger btn-sm mr-1" (click)="onDeleteEmail(templateDelete,email.id)" [ngbTooltip]="tipContentDelete"
                >
                <i class="fas fa-trash"></i>
            </button></td>
        </tr>
    </tbody>
</table>
</div>
<ng-template #templateAdd>
    <div class="modal-header border-0 text-white text-center bg-white p-0 pr-1 pt-1">
        <!-- <h4 class="modal-title text-white ml-auto text-center">Ajouter L'Emails</h4> -->
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden" style="color: black !important">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
    <div>
        <tabset>
            <tab heading="Saisie Unitaire"> 
            <form class="mt-2" [formGroup]="emailForm">
                    <div class="form-group text-left mb-0">
                        <label for="email">Email <span class="star">*</span></label>
                        <input type="email" spellcheck="false"
                            [ngClass]="{ 'error':fields.email.touched && fields.email.invalid ,'success-class':fields.email.touched && fields.email.valid }"
                            id="email" formControlName="email" class="form-control" name="email" required>
                    </div>
                    
                    <div *ngIf="fields.email.errors">
                        <p class="text-left mb-0">
                            <span *ngIf="fields.email.touched && fields.email.errors?.required" class="error-msg">L'adresse email
                                obligatoire</span>
                            <span
                                *ngIf="(fields.email.touched && fields.email.invalid) || (fields.email.touched && fields.email.errors?.pattern) "
                                class="error-msg">
                                Entrer un email valide
                            </span>
                        </p>
                    
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <div>
                            <button type="button" class="btn btn-light text-secondary border-0 rounded-0" (click)="onCancel()"> <i class="fas fa-times" style="padding-right: 5px;"></i>Annuler</button>
                        </div>
                        <div> 
                        <button  *ngIf="!isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"  [disabled]="!emailForm.valid" (click)="onSubmitSingleEmail()"> <i class="fas fa-save" style="padding-right: 5px;"></i>Enregistrer</button>
                        <button *ngIf="isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"  [disabled]="!emailForm.valid" (click)="onUpdateSingleEmail()"> <i class="fas fa-save"  style="padding-right: 5px;"></i>Mis à jour</button>
                        </div>
                    </div>
            </form>
            </tab>
            <tab heading="Saisie Groupé" *ngIf="!isEdit">
                <div class="w-100 text-right">
                <a download="email_model.xlsx" class="btn btn-primary btn-primary-r mr-1 mt-1" role="button" target="_blank"
                    href="../../../assets/excel/email_model.xlsx" style=" padding: 8px 10px !important;">
                    Télécharger le modèle <img src="../../../assets/icons/download-file-icon.svg" width="22" height="10" />
                </a>
                </div>
               <div class="mt-2 mb-2">
                <input class="form-control" accept=".xlsx" type="file"  (change)="uploadFile($event)">
               </div>
           
            </tab>
        </tabset>
    </div>
    </div>
</ng-template>
<ng-template #templateDelete>
    <div class="modal-header border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Supprimer le mail</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2  edit-lead-modal-body">
        <p class="text-center">Voulez-vous supprimer le mail?</p>
        <div class="d-flex justify-content-between">

            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                    style="padding: 0.4rem 1rem !important;">Non</button>
            </div>
            <div>
                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
            </div>
        </div>
    </div>
</ng-template>

</div>

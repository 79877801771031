import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ENV } from 'src/app/core/env.config';
import { DatacatchingService } from '../../services/datacatching.service';
import Swal from 'ngx-angular8-sweetalert2';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  masterEvents: any = [];
  p = 1;
  total: number;
  itemsPerPage: number;
  field: String = 'date_creation';
  order: String = 'DESC';
  searchTerm: string = '';
  isLoading: boolean = false;
  isEmptyEvents: boolean = false;
  constructor(private datacatchingService: DatacatchingService, private router: Router, private datePipe: DatePipe, private toastr: ToastrService,) {
    if ("redirectToTemplate" in sessionStorage) sessionStorage.removeItem("redirectToTemplate");
    if ("masterId" in sessionStorage) sessionStorage.removeItem("masterId");
    if ("id" in sessionStorage) sessionStorage.removeItem("id");
   }

  async getEvents(page, field, order) {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.isLoading = true;
    let url = `${ENV.API_URL}v2/events/master?field=${field}&order=${order}&page=${page}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/events/master?field=${field}&order=${order}&page=${page}&size=8&term=${this.searchTerm}`;
    }
   
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(eventsList => {
      this.masterEvents = eventsList['events'];
      this.total = eventsList['totalItems'];
      this.itemsPerPage = eventsList['itemsPerPage'];
      this.p = eventsList['currentPage'];
      if (eventsList['events'].length === 0) {
        this.isEmptyEvents = true;
      }
      this.isLoading = false;
    });
    
  }

  ngOnInit(): void {
    this.getEvents(1, this.field, this.order);
    
  }

  clearFilter() {
    this.searchTerm = '';
    this.getEvents(1, this.field, this.order);
  }
  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.getEvents(this.p, this.field, this.order);

  }

  filterEvents() {
    this.getEvents(1, this.field, this.order);
  }
  
  pageChanged(pageNum) {
    this.getEvents(pageNum, this.field, this.order);
  
  }
  onTemplateEdit(event_id) {
    this.router.navigate(["/events"], { queryParams: { templateId: event_id } });
    
  }
  onEdit(id) {
    this.router.navigate(["/events"], { queryParams: { id: id } });
  }
  transfromDate(date) {
    return this.datePipe.transform(date, 'medium')
  }
  
  async onDelete(id, eventName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: `Vous ne pourrez pas récupérer ${eventName} événement !`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimez-le!',
      cancelButtonText: 'Non, garde-le'
    })
      .then((result) => {
        if (result.value) {
          let url = `${ENV.API_URL}v2/event/delete/${id}`;
          this.datacatchingService.deleteApi(url, isTokenNotExpired, 'normal').subscribe(res => {
            if (res['success']) {
              this.getEvents(1, this.field, this.order);
              this.toastr.success(res['message']);
            } else {
              this.toastr.error(res['message']);
            }
          });
          Swal.fire(
            'Supprimé !',
            `Le <strong> ${eventName} </strong> événement a été supprimé`,
            'success'
          )
          // this.getEvents();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Annulé',
            `Le <strong> ${eventName} </strong> événement a est sûr:)`,
            'error'
          )
        }
      })
  }

}

<div class="app-content content mt-5 pt-4" >
    <div class="container col-md-8 col-sm-12 col-xs-12">
    <div class="auto-container-error">
        <div class="error-number">
            <p>500</p>
        </div>
        <div class="image-error">
            <div><strong>OUPS ! Erreur lors du traitement de vos données.</strong>
            </div>
            <br />
            <br />
            <button (click)="gotoHome()" class="btn btn-warning-danger">REVENIR</button>
        </div>
    </div>
</div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ENV } from '../../core/env.config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mail-alert',
  templateUrl: './mail-alert.component.html',
  styleUrls: ['./mail-alert.component.scss']
})
export class MailAlertComponent implements OnInit {
  modalRef?: BsModalRef;
  emailsList = [];
  emailId:number=null;
  isEdit: boolean = false;
  excelfileToUpload: File = null;
  public emailForm: UntypedFormGroup;
  config = {
    class: 'modal-md',  
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private modalService: BsModalService, private toastr: ToastrService, private formBuilder: UntypedFormBuilder, private datacatchingService: DatacatchingService) {
    this.emailForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^(?:s)?[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,}(?:s)?$"),
        ],
      ]
    });
  }
  onCancel() {
    this.modalRef.hide();
    this.emailForm.reset();
    this.isEdit = false;
  }

  onAddEmail(templateAdd, type, id) {
    this.isEdit = false;
    this.emailForm.reset();
    this.modalRef = this.modalService.show(templateAdd,this.config );
    if (id !== null && type == "edit") {
      this.isEdit = true;
      let emailObj = this.emailsList.filter(emailInstance => id === emailInstance.id);
      this.emailId = emailObj[0].id; 
      this.emailForm.patchValue({ email: emailObj[0].email });
    }
  }
  get fields() {
    return this.emailForm.controls
  }
  async onSubmitSingleEmail() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/leads/mail/create`;
    let obj = {email:this.emailForm.getRawValue().email}
    this.datacatchingService.postApi(url, obj, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      this.getEmail();
      this.toastr.success("L'email ajouté avec succès");
      this.modalRef.hide();
      this.emailForm.reset();
    })
  }
 async onUpdateSingleEmail() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/leads/mail/update`;
    let obj = { email: this.emailForm.getRawValue().email, id:this.emailId }
    this.datacatchingService.updateApi(url, obj, isTokenNotExpired).subscribe(res => {
      this.getEmail();
      this.toastr.success("L'email mis à jour avec succès");
      this.modalRef.hide();
      this.emailForm.reset();
    })
  }

  async getEmail() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/leads/mail/read`;
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      this.emailsList = res['result'];
    })
  }
  async onDelete() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/leads/mail/delete/${this.emailId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['result']['rowCount'] === 1) {
        // this.modifyLeads(this.p, this.field, this.order);
        this.getEmail()
        this.modalRef.hide();
        this.toastr.success("L'email a été supprimé avec succès");

      }
    })
  }
  onDeleteEmail(templateDelete, id) {
    this.emailId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
    
  }

  ngOnInit(): void {
    this.getEmail();

  }
  async uploadFile(event) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.excelfileToUpload = <File>event.target.files[0];
    console.log("this.excelfileToUpload", this.excelfileToUpload)
    let fileName = this.excelfileToUpload.name.replaceAll(" ", "-").split(".");
    const formData = new FormData();
    var timeStamp = new Date().getTime();
    formData.append('file', this.excelfileToUpload, `${fileName[0]}-${timeStamp}.xlsx`);
    this.datacatchingService.postApi(`${ENV.API_URL}v2/leads/mail/upload`, formData, isTokenNotExpired, 'file').subscribe(res => {
      this.modalRef.hide();
      this.toastr.success("L'email ajouté avec succès");
      this.getEmail();
    })
  }
 
}

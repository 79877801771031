<div class="w-25 ml-auto mb-1 text-right">
    <button type="button" class="btn btn-primary btn-primary-r mr-1" (click)="onAddLanguage(templateRegexAdd,'add', null)"><i
            class="fas fa-plus" style="padding-right: 5px;"></i>
        Ajouter un nouveau</button>
</div>
<div class="table-wrapper m-1 mb-2">



    <table class="table bg-white table-striped">
        <thead>
            <tr>
                <th scope="col">N°</th>
                <th scope="col">Pays</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let translation of translations;let i=index">
                <td>{{i+1}}</td>
                <td>{{translation.country}}</td>
                <td><ng-template #tipContentEdit>Modifier</ng-template>
                    <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit"
                        (click)="onAddLanguage(templateRegexAdd, 'edit',translation.id)">
                        <i class="fas fa-pen"></i>
                    </button>
                    <ng-template #tipContentDelete>Suprrimer</ng-template>
                    <button type="button" class="btn btn-danger btn-sm mr-1"
                        (click)="onDeleteLanguage(templateRegexDelete,translation.id)" [ngbTooltip]="tipContentDelete">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #templateRegexAdd>
    <div class="modal-header border-0 text-white text-center bg-white p-0 pr-1 pt-1">
        <!-- <h4 class="modal-title text-white ml-auto text-center">Ajouter L'Emails</h4> -->
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden" style="color: black !important">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
        <div>
            <form class="mt-2" [formGroup]="regexForm">

            <div class="form-group text-left mb-1">
                <label for="email">Pays<span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="country" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Telephone <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="telephone" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Téléphone portable <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="telephonePortable" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Téléphone fixe <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="telephoneFixe" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Nom ou Prénom <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="nomOuPrenom" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Email <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="email" class="form-control">
            </div>

            <div class="form-group text-left mb-1">
                <label for="email">Code postal <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="codePostal" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Siret <span class="star">*</span></label>
                <input type="text" spellcheck="false" formControlName="siret" class="form-control">
            </div>
            <div class="form-group text-left mb-1">
                <label for="email">Autre</label>
                <input type="text" spellcheck="false" formControlName="autre" class="form-control">
            </div>
                <div class="d-flex justify-content-between mt-3">
                    <div>
                        <button type="button" class="btn btn-light text-secondary border-0 rounded-0"
                            (click)="onCancel()"> <i class="fas fa-times"
                                style="padding-right: 5px;"></i>Annuler</button>
                    </div>
                    <div>
                        <button *ngIf="!isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"
                             (click)="onSubmit()"  [disabled]="!regexForm.valid" > <i class="fas fa-save"
                                style="padding-right: 5px;"></i>Enregistrer</button>
                        <button *ngIf="isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"
                            [disabled]="!regexForm.valid" (click)="onUpdate()"> <i class="fas fa-save"
                                style="padding-right: 5px;"></i>Enregistrer</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #templateRegexDelete>
    <div class="modal-header border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Supprimer le traduction</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2  edit-lead-modal-body">
        <p class="text-center">Voulez-vous supprimer le traduction?</p>
        <div class="d-flex justify-content-between">

            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                    style="padding: 0.4rem 1rem !important;">Non</button>
            </div>
            <div>
                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
            </div>
        </div>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import {openDB, DBSchema, IDBPDatabase,} from 'idb'
@Injectable({
  providedIn: 'root'
})
export class IndexeDBService {
  private db;
  constructor() {
    this.connectToDb()
   }

  async connectToDb() {
    this.db = await openDB('dealerDB', 1, {
      upgrade(db) {
        if(!db.objectStoreNames.contains('dealerStore')) {
          db.createObjectStore('dealerStore', {
            keyPath: 'id',
          });
        } 
        if (!db.objectStoreNames.contains('leadStore')) {
          db.createObjectStore('leadStore', {
            autoIncrement: true
          });
        }
      },
    });
    this.clearStoreLead()
    this.clearStore()
    }

  async addDealer(id: number, nom_affaire: string, code_rrf: string, code_postale: string) {
    if (this.db) {
      const tx = this.db.transaction('dealerStore', 'readwrite');
      await tx.objectStore('dealerStore').put({
        'id': id,
        'nom_affaire': nom_affaire,
        'code_rrf': code_rrf,
        'code_postale': code_postale,
      });
      return tx.complete;
    }

  }

  getDealers(){
    const tx = this.db.transaction('dealerStore', 'readonly');
    const store = tx.objectStore('dealerStore');
    return store.getAll();
  }

  clearStore() {
    if(this.db) {
      const tx = this.db.transaction('dealerStore', 'readwrite');
      const store = tx.objectStore('dealerStore');
      store.clear();
  
      return tx.complete
    }
  }

  async createLead(lead : Object) {
    const tx = this.db.transaction('leadStore', 'readwrite');
    await tx.objectStore('leadStore').put(lead);

    return tx.complete;
  }

  clearStoreLead() {
    if (this.db) {
      const tx = this.db.transaction('leadStore', 'readwrite');
      const store = tx.objectStore('leadStore');
      store.clear();
      return tx.complete
    }
  }


  getLead() {
  const tx = this.db.transaction('leadStore', 'readonly');
  const store = tx.objectStore('leadStore');
  return store.getAll();
}

}
<div class="page-wrapper chiller-theme toggled">
    <a id="show-sidebar" class="pl-2" style="color:#000" (click)="toggleSideBar()">
      <i class="fa fa-bars" style="font-size: 1.4rem;color:#000;"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <!-- <a href="/home">CATCHY</a> -->
          <div id="close-sidebar">
            <i (click)="closeSideBar()" class="fa fa-times"></i>
          </div>
        </div>
        <!-- sidebar-search  -->
        <div class="sidebar-menu">
          <ul>
            <li class="sidebar-dropdown nav-item">
             <a class="nav-link" routerLinkActive="active-link" routerLink="/forms"><i class="icon-side fa fa-list"></i><span>Liste des évènements</span></a>
            </li>
            <li class="sidebar-dropdown nav-item">
              <a class="nav-link" routerLinkActive="active-link" routerLink="/template"> <i class="icon-side fa fa-list-alt" aria-hidden="true"></i><span>Liste
                  des templates</span></a>
            </li>
            <li class="sidebar-dropdown nav-item">
              <a class="nav-link" routerLinkActive="active-link" (click)="resetEvent()" routerLink="/events"><i class="icon-side fa fa-plus"></i><span>Créer un évènement</span></a>
            </li>
            <li class="sidebar-dropdown">
              <a class="nav-link" routerLinkActive="active-link" routerLink="/translation"> <i
                  class="icon-side fa fa-tasks"></i><span>Traduction</span></a>
            </li>
            <li class="sidebar-dropdown">
              <a class="nav-link" routerLinkActive="active-link" routerLink="/charte"> <i
                  class="icon-side fa fa-font"></i><span>Charte</span></a>
            </li>
            <li class="sidebar-dropdown">
              <a class="nav-link" routerLinkActive="active-link" routerLink="/country"> <i
                  class="icon-side fa fa-globe"></i><span>Pays</span></a>
            </li>
            <li class="sidebar-dropdown">
             <a class="nav-link" routerLinkActive="active-link" routerLink="/lead"> <i class="icon-side fa fa-chart-line"></i><span>Liste des leads</span></a>
            </li>
              <li class="sidebar-dropdown">
                <a class="nav-link" routerLinkActive="active-link" routerLink="/lead-timing"> 
                  <i class="icon-side fas fa-hourglass-half"></i> <span>Leads en cours</span></a>
              </li>
                <li class="sidebar-dropdown">
                  <a class="nav-link" routerLinkActive="active-link" routerLink="/lead-error">
                  <i class="icon-side fas fa-exclamation-triangle"></i> <span>Leads en erreur</span></a>
                </li>
                <li class="sidebar-dropdown">
                  <a class="nav-link" routerLinkActive="active-link" routerLink="/lead-error-mail-alert">
                    <i class="icon-side fa fa-envelope"></i> <span>Mails Alerte</span></a>
                </li>
                  <li class="sidebar-dropdown">
                    <a class="nav-link" routerLinkActive="active-link" routerLink="/historique">
                      <i class="icon-side fa fa-history"></i> <span>Historique</span></a>
                  </li>
          </ul>
        </div>
        <!-- sidebar-menu  -->
      </div>
    </nav>
    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="container p-0" style='max-width: 1220px !important;'>
          <nav class="header-navbar navbar-expand-md navbar navbar-light navbar-with-menu navbar-without-dd-arrow fixed-top navbar-light
          navbar-hide-on-scroll navbar-brand-center headroom headroom--not-bottom headroom--pinned-top
          headroom--top" data-nav="brand-center">
              <div class="navbar-wrapper">
                
                  <div class="navbar-header">
                     
                      <ul class="nav navbar-nav flex-row">
                      
                          <li class="nav-item"><a class="navbar-brand waves-effect waves-dark" href="/home"><img
                                      class="brand-logo" alt="modern admin logo" style="font-size:50%"
                                      src="../../../assets/images/logo/logo-groupe-renault-new.svg">
                              </a></li>
                          <li class="dropdown dropdown-user nav-item" id="desktop-admin-panel"><a
                                  style="padding: 15px !important;"
                                  class="dropdown-toggle nav-link dropdown-user-link waves-effect waves-dark"
                                  (click)="logout()" ><span
                                      class="avatar avatar-online"><img width='30'
                                          src="../../../assets/icons/logoff.jpg" 
                                          alt="avatar"></span></a>
                          </li>
                      </ul>
                  </div>
                  <div class="navbar-container content">
                      <div class="collapse navbar-collapse" id="navbar-mobile">
                          <ul class="nav navbar-nav mr-auto float-left">
                              <strong class="ml-3">CATCHY</strong>
                          </ul>
                          <ul class="nav navbar-nav float-right">
                              <li class="dropdown dropdown-user nav-item"><a
                                      class="dropdown-toggle nav-link dropdown-user-link waves-effect waves-dark"
                                        (click)="logout()"><span
                                          class="avatar avatar-online"><img width='30'
                                          src="../../../assets/icons/logoff.jpg"
                                              alt="avatar"></span></a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </nav>
  
          <router-outlet></router-outlet>
        </div>
    </main>
    <!-- page-content" -->
  </div>
  <!-- page-wrapper -->

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ENV } from '../../core/env.config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  modalRef?: BsModalRef;
  countries = [];
  countryId:number=null;
  isEdit: boolean = false;
  excelfileToUpload: File = null;
  public countryForm : FormGroup;
  config = {
    class: 'modal-md',  
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private modalService: BsModalService, private toastr: ToastrService, private formBuilder: FormBuilder, private datacatchingService: DatacatchingService) {
    this.countryForm = this.formBuilder.group({
      name: ["",[ Validators.required]],
      code: ["",[ Validators.required,Validators.maxLength(2),Validators.minLength(2)]]
    });
  }
  onCancel() {
    this.modalRef.hide();
    this.countryForm.reset();
    this.isEdit = false;
  }

  onAddcode(templateAdd, type, id) {
    this.isEdit = false;
    this.countryForm.reset();
    this.modalRef = this.modalService.show(templateAdd,this.config );
    if (id !== null && type == "edit") {
      this.isEdit = true;
      let countryObj = this.countries.filter(countryInstance => id === countryInstance.id);
      this.countryId = countryObj[0].id; 
      this.countryForm.patchValue({ name: countryObj[0].name,code: countryObj[0].code});
      
    }
   
  }
  get fields() {
    return this.countryForm.controls
  }

  async onSubmitSinglecode() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/country`;
    let obj = {...this.countryForm.getRawValue()}
    this.datacatchingService.postApi(url, obj, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      this.getCountries();
      this.toastr.success("L'email ajouté avec succès");
      this.modalRef.hide();
      this.countryForm.reset();
    })
  }

 async onUpdateSinglecode() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/country`;
    let obj = { name: this.countryForm.getRawValue().name,code: this.countryForm.getRawValue().code, id:this.countryId }
    this.datacatchingService.updateApi(url, obj, isTokenNotExpired).subscribe(res => {
      this.getCountries();
      this.toastr.success("L'email mis à jour avec succès");
      this.modalRef.hide();
      this.countryForm.reset();
    })
  }

  async getCountries() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    let url = `${ENV.API_URL}v2/countries`;
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      this.countries = res['result'];
    })
  }

  async onDelete() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/country/${this.countryId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['result']['rowCount'] === 1) {
        this.getCountries()
        this.modalRef.hide();
        this.toastr.success("L'email a été supprimé avec succès");

      }
    })
  }
  onDeletecode(templateDelete, id) {
    this.countryId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
    
  }

  ngOnInit(): void {
    this.getCountries();

  }
  async uploadFile(event) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.excelfileToUpload = <File>event.target.files[0];
    console.log("this.excelfileToUpload", this.excelfileToUpload)
    let fileName = this.excelfileToUpload.name.replaceAll(" ", "-").split(".");
    const formData = new FormData();
    var timeStamp = new Date().getTime();
    formData.append('file', this.excelfileToUpload, `${fileName[0]}-${timeStamp}.xlsx`);
    this.datacatchingService.postApi(`${ENV.API_URL}v2/leads/mail/upload`, formData, isTokenNotExpired, 'file').subscribe(res => {
      this.modalRef.hide();
      this.toastr.success("L'email ajouté avec succès");
      this.getCountries();
    })
  }
}

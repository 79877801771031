 

const fieldModels: Array<any> = [
    {
        "type": "leadCountry",
        "icon": "fa-caret-square-down",
        "label": "Pays",
        "specific": false,
        'isLead': true,
        "required": true,
        "formControlName": "leadCountry",
        "leadRequired": "leadInfo",
        "description": "Pays",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "label": "France",
                "value": "France"
            }
        ]
    },
    {
        "type": "marque-lead",
        "icon": "fa-caret-square-down",
        "label": "Marque constructeur",
        //"specific": true,
        "description": "marque",
        "placeholder": "marque",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "platformBrand",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "RENAULT",
                "value": "RENAULT",
            },
            {
                "label": "DACIA",
                "value": "DACIA",
            },
            {
                "label": "ALPINE",
                "value": "ALPINE",
            }
        ]
    },
    {
        "type": "source",
        "icon": "fa-caret-square-down",
        "label": "Canal",
        //"specific": true,
        "description": "source",
        "placeholder": "source",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "leadSource",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "DATACATCHING",
                "value": "DATACATCHING",
            },
            {
                "label": "INTERNET",
                "value": "INTERNET",
            },
            {
                "label": " EVENT",
                "value": "Events",
            },
            {
                "label": "CAMPAGNE",
                "value": "Campaign",
            },
            {
                "label": "DEALER",
                "value": "Dealer",
            },
            {
                "label": "LOCAL",
                "value": "Local",
            },
        ]
    },
    {
        "type": "demande-lead",
        "icon": "fa-caret-square-down",
        "label": "Type d'intérêt",
        "specific": true,
        "required": true,
        "description": "demande",
        "placeholder": "demande",
        "name": "type de demande",
        'isLead': true,
        "className": "form-control",
        "formControlName": "typeOfInterest",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "VN",
                "value": "VN"
            },
            {
                "label": "VO",
                "value": "VO"
            },
            {
                "label": "APV",
                "value": "APV"
            }
        ]
    },
    {
        "type": "subType",
        "icon": "fa-caret-square-down",
        "label": "Sous-type d'intérêt",
        //"specific": true,
        "description": "subType",
        "placeholder": "subType",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "subTypeOfInterest",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "Demande d'essai",
                "value": "TestDrive Request",
            },
            {
                "label": "Demande de brochure",
                "value": "Brochure Request",
            },
            {
                "label": "Demande de ebrochure",
                "value": "eBrochure Request",
            },
            {
                "label": "Demande de devis",
                "value": "Quote request",
            },
            {
                "label": "Demande de reprise",
                "value": "Trade in request",
            },
            {
                "label": "Demande atelier",
                "value": "Service request",
            },
            {
                "label": "Demande d'achat avec financement",
                "value": "Finance request",
            },
            {
                "label": "Demande d'achat",
                "value": "Purchase request",
            },
            {
                "label": "Demande d'accessoire",
                "value": "Accessory request",
            },
            {
                "label": "Demande d'information",
                "value": "General request",
            },
            {
                "label": "Reveal",
                "value": "Reveal",
            },
            {
                "label": "Newsletter",
                "value": "Newsletter",
            },
            {
                "label": "Jeux",
                "value": "Game",
            },
            {
                "label": "Parrainage",
                "value": "Sponsorship",
            },
            {
                "label": "Pop-up store",
                "value": "Pop-up store",
            },
        ]
    },
    {
        "type": "context",
        "icon": "fa-caret-square-down",
        "label": "Contexte",
        //"specific": true,
        "description": "context",
        "placeholder": "context",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "context",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "catchy-leadform",
                "value": "catchy-leadform",
            },
            {
                "label": "catchy-event",
                "value": "catchy-event",
            }
        ]
    },
    {
        "type": "origin",
        "icon": "fa-caret-square-down",
        "label": "Origine (Sous-canal)",
        //"specific": true,
        "description": "origin",
        "placeholder": "origin",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "origin",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "crm",
                "value": "Crm",
            },
            {
                "label": "direct",
                "value": "Direct",
            },
            {
                "label": "liens-commerciaux",
                "value": "Commercial links",
            },
            {
                "label": "no-cookie",
                "value": "No-cookie",
            },
            {
                "label": "referencement-naturel",
                "value": "Natural Referencement",
            },
            {
                "label": "pub-internet",
                "value": "Pub-internet",
            },
            {
                "label": "sites-externes",
                "value": "External sites",
            },
            {
                "label": "emailing-no-paid",
                "value": "Emailing-no-paid",
            },
            {
                "label": "social-no-paid",
                "value": "Social-no-paid",
            },
            {
                "label": "emailing-paid",
                "value": "Emailing-paid",
            },
            {
                "label": "social-paid",
                "value": "Social-paid",
            },
            {
                "label": "adexchange",
                "value": "Adexchange",
            },
            {
                "label": "non-defini",
                "value": "Non-defini",
            },
            {
                "label": "prm",
                "value": "prm",
            },
            {
                "label": "Off-line",
                "value": "off-line",
            },
            {
                "label": "Hostess-collection",
                "value": "hotesses-collecte",
            },
            {
                "label": "Bornes-collecte",
                "value": "Collection terminals",
            },
            {
                "label": "Showroom",
                "value": "Show Room",
            },
            {
                "label": "Salon / Evénement",
                "value": "Show / Event",
            },
        ]
    },
    {
        "type": "campaign",

        "icon": "fa-caret-square-down",
        "label": "Campagne",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "campaign",
        "leadRequired": "leadInfo",
        "description": "Campagne",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "label": "campaign",
                "value": "spring-tour-leclerc-op-dcf"
            }
        ]
    },
    {
        "type": "device",
        "icon": "fa-caret-square-down",
        "label": "Device",
        //"specific": true,
        "description": "device",
        "placeholder": "device",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "device",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "Ordinateur",
                "value": "desktop",
            },
            {
                "label": "Tablette",
                "value": "tablet",
            },
            {
                "label": "Mobile",
                "value": "mobile",
            },
            {
                "label": "non-defini",
                "value": "undefined",
            }
        ]
    },
    {
        "type": "leadProvider",
        "icon": "fa-caret-square-down",
        "label": "CreationOrigin",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "leadProvider",
        "leadRequired": "leadInfo",
        "description": "Fournisseur",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "label": "CATCHY",
                "value": "CATCHY"
            }
        ]
    },
    {
        "type": "formName",
        "icon": "fa-caret-square-down",
        "label": "Leadform Name",
        //"specific": true,
        "description": "formName",
        "placeholder": "formName",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "leadFormName",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "CATCHY-LEADFORM",
                "value": "CATCHY-LEADFORM",
            },
            {
                "label": "CATCHY-EVENT",
                "value": "CATCHY-EVENT",
            },
            {
                "label": "CATCHY-",
                "value": "CATCHY-",
            }
        ]
    },
    {
        "type": "destination",
        "icon": "fa-caret-square-down",
        "label": "Destination",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "reminderResponsible",
        "leadRequired": "client",
        "description": "Destination",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "label": "HISTO",
                "value": "HISTO"
            },
            {
                "label": "CSMO",
                "value": "CSMO"
            },
            {
                "label": "PTF LOCALE",
                "value": "PTF LOCALE"
            },
            {
                "label": "PTF NATIONALE",
                "value": "PTF NATIONALE"
            },
            {
                "label": "DEALER",
                "value": "DEALER"
            },
            {
                "label": "ZEPHIR",
                "value": "ZEPHIR"
            },
            {
                "label": "VOUS",
                "value": "VOUS"
            },
            {
                "label": "ALHENA",
                "value": "ALHENA"
            },
            {
                "label": "ARVATO",
                "value": "ARVATO"
            },
            {
                "label": "CRC",
                "value": "CRC"
            },
        ]
    },
    {
        "type": "dealer",
        "label": "Dealer",
        "icon": "fa-caret-square-down",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "dealerOfInterest",
        "leadRequired": "dealer",
        "description": "Destination",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "name": "Sans dealer",
                "value": "Sans dealer"
            },
            {
                "name": "Un dealer",
                "value": "Un dealer"
            },
            {
                "name": "Liste déroulante des dealers participants",
                "value": "Liste déroulante des dealers participants"
            },
            {
                "name": "Fichier excel CP > RRF",
                "value": "Fichier excel CP > RRF"
            },
            {
                "name": "API WIRED",
                "value": "API WIRED"
            },
        ]
    },



    {
        "type": "GDPR",
        "icon": "fa-list-ul",
        "label": "GDPR",
        "specific": false,
        "description": "GDPR",
        "text": "Conditions générale",
        "values": [
            {
                "type": "gdpr-label",
                "specific": true,
                "isInput": false,
                "label": "Je consens à l’utilisation de mes données personnelles pour recevoir des offres personnalisées de la part de Renault SAS et des membres de son réseau commercial :",
                "hide": false,
                "className": "form-control",
                "formControlName": "gdpr-label",

            },

            {
                "type": "gdpr-email",
                "label": "Par email",
                "required": true,
                "formControlName": "emailMarketingOptin",
                "leadRequired": "client",
                "name": "gdprEmail",
                "errorText": "Le champ email n'est pas complet",
                "hide": false,
                "isInput": true,
                "valuesGdpr": [
                    {
                        "label": "Oui",
                        "name": "gdprEmail",
                        "value": "Y",
                        "InputType": "radio",

                    },
                    {
                        "label": "Non",
                        "name": "gdprEmail",
                        "value": "N",
                        "InputType": "radio"
                    }
                ]
            },
            {
                "type": "gdpr-SMS",
                "label": "Par sms",
                "required": true,
                "formControlName": "smsMarketingOptin",
                "leadRequired": "client",
                "name": "gdprSms",
                "errorText": "Le champ sms n'est pas complet",
                "hide": false,
                "isInput": true,
                "valuesGdpr": [
                    {
                        "label": "Oui",
                        "name": "gdprSms",
                        "value": "Y",
                        "InputType": "radio"
                    },
                    {
                        "label": "Non",
                        "name": "gdprSms",
                        "value": "N",
                        "InputType": "radio"
                    }
                ]
            },
            // {
            //   "type": "gdpr-Tel",
            //   "label": "Accord de communication par téléphone",
            //   "required": true,
            //   "formControlName": "phoneMarketingOptin",
            //   "leadRequired": "client",
            //   "name": "gdprPhone",
            //   "errorText": "Le champ téléphone n'est pas complet",
            //   "hide": false,
            //   "isInput": true,
            //   "valuesGdpr": [
            //     {
            //       "label": "Oui",
            //       "name": "gdprPhone",
            //       "value": "Y",
            //       "InputType": "radio"
            //     },
            //     {
            //       "label": "Non",
            //       "name": "gdprPhone",
            //       "value": "N",
            //       "InputType": "radio"
            //     }
            //   ]
            // },
            // {
            //   "type": "gdpr-postale",
            //   "label": "Accord de communication par voie postale",
            //   "required": true,
            //   "formControlName": "postalMarketingOptin",
            //   "leadRequired": "client",
            //   "name": "gdprPostal",
            //   "errorText": "Le champ code postal n'est pas complet",
            //   "hide": false,
            //   "isInput": true,
            //   "valuesGdpr": [
            //     {
            //       "label": "Oui",
            //       "name": "gdprPostal",
            //       "value": "Y",
            //       "InputType": "radio"
            //     },
            //     {
            //       "label": "Non",
            //       "name": "gdprPostal",
            //       "value": "N",
            //       "InputType": "radio"
            //     }
            //   ]
            // },
        ]
    },
    {
        "type": "composeType",
        "icon": "fa-gas-pump",
        "label": "Vehicule d'intérêt",
        "specific": false,
        "className": "form-control",
        "values": [],
        "detailValues": [{
            "detailMarque": [
                {
                    "type": "marque",
                    "label": "Marque",
                    "specific": true,
                    "hide": false,
                    "required": true,
                    "formControlName": "brandOfInterest",
                    "leadRequired": "vehicle",
                    "description": "",
                    "placeholder": "sélectionnez une marque",
                    "valueSelected": ""
                }],
            "detailModele": [
                {
                    "type": "modele",
                    "label": "Modele",
                    "specific": true,
                    "hide": false,
                    "required": true,
                    "formControlName": "modelOfInterest",
                    "leadRequired": "vehicle",
                    "description": "modele",
                    "placeholder": "sélectionnez un modele",
                    "className": "form-control",
                    "valueSelected": ""
                }],
            "detailEnergie": [
                {

                    "type": "energie",
                    "label": "Energie",
                    "specific": false,
                    "hide": false,
                    "required": true,
                    "description": "energie",
                    "placeholder": "sélectionnez une energie",
                    "className": "form-control",
                    "formControlName": "energy",
                    "leadRequired": "vehicle",
                    "valueSelected": ""
                }
            ],
        }]
    },
    {
        "type": "carsWish",
        "icon": "fa-car",
        "label": "Véhicule possédé",
        "specific": false,
        "className": "form-control",
        "values": [],
        "detailValues": [{
            "detailMarque": [
                {
                    "type": "marque",
                    "label": "Marque possédé",
                    "specific": true,
                    "hide": false,
                    "required": true,
                    "formControlName": "brand",
                    "leadRequired": "ownedVehicle",
                    "description": "",
                    "placeholder": "sélectionnez une marque",
                    "valueSelected": ""
                }],
            "detailModele": [
                {
                    "type": "modele",
                    "label": "Modele possédé",
                    "specific": true,
                    "hide": false,
                    "required": true,
                    "formControlName": "model",
                    "leadRequired": "ownedVehicle",
                    "description": "modele",
                    "placeholder": "sélectionnez un modèle",
                    "className": "form-control",
                    "valueSelected": ""
                }],
            "detailEnergie": [
                {
                    "type": "energie",
                    "label": "Energie ",
                    "specific": true,
                    "hide": false,
                    "required": true,
                    "description": "energie",
                    "placeholder": "sélectionnez une energie",
                    "className": "form-control",
                    "formControlName": "energyOwned",
                    "leadRequired": "ownedVehicle",
                    "valueSelected": ""
                }
            ],
        }]
    },
    {
        "type": "CGU",
        "icon": "fa-check-square",
        "specific": false,
        "required": true,
        "label": "CGU",
        "text": "J'ai lu et j'accepte",
        "className": "form-control",
        "formControlName": "termOfUseAgreement",
        "leadRequired": "client",
        "subtype": "text",
        "regex": "",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
        "link": "https://cdn.group.renault.com/dac/fr/transversal-assets/Reglement_Programme_de_Parrainage_Dacia_2020.pdf"
    },
    {
        "type": "mentions",
        "icon": "fa-balance-scale-right",
        "specific": false,
        "required": true,
        "label": "M Légales",
        "text": "",
        "className": "form-control",
        "formControlName": "",
        "leadRequired": "",
        "subtype": "text",
        "regex": "",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
        "isShortLegales": true,
        "isPdf": false,
        "pdf_file_path": null,
        "values": [
            {
                "label": "M Légales Court",
                "name": "m-legale",
                "val": "M-short",
                "isChecked": true,
                "InputType": "radio"
            },
            {
                "label": "M Légales pdf",
                "name": "m-legale",
                "val": "M-pdf",
                "isChecked": false,
                "InputType": "radio"
            }
        ],
        "mentionsFile": {
            "type": "file",
            "icon": "fa-file",
            "specific": false,
            "required": true,
            "label": "File Upload",
            "className": "form-control",
            "formControlName": "file",
            "leadRequired": "",
            "subtype": "file",
            "errorText": "Merci de remplir ce champs",
            "hide": false,
        },

    },

    {
        "type": "text",
        "icon": "fa-font",
        "specific": false,
        "required": true,
        "label": "Text",
        "description": "text",
        "placeholder": "text",
        "formControlName": "text",
        "leadRequired": "",
        "className": "form-control",
        "subtype": "text",
        "normalisateur": "",
        "regex": "",
        "errorText": "Merci de saisir votre texte",
        "hide": false,
        "handle": true
    },
    {
        "type": "email",
        "icon": "fa-envelope",
        "specific": false,
        "required": true,
        "label": "Email",
        "description": "email",
        "placeholder": "email",
        "formControlName": "email",
        "leadRequired": "",
        "className": "form-control",
        "subtype": "text",
        "normalisateur": "",
        "regex": "^([a-z0-9_.-]+)@([a-z0-9_.-]+)\.([a-z]{2,5})$",
        "errorText": "Merci de saisir votre email",
        "hide": false,
        "handle": true
    },
    {
        "type": "phone",
        "icon": "fa-phone",
        "specific": false,
        "required": true,
        "label": "Phone",
        "description": "Enter your phone",
        "placeholder": "Enter your phone",
        "className": "form-control",
        "leadRequired": "",
        "formControlName": "phone",
        "subtype": "text",
        "normalisateur": "",
        "regex": "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
        "errorText": "Merci de saisir votre numéro de téléphone",
        "hide": false,
        "handle": true
    },
    {
        "type": "number",
        "label": "Number",
        "specific": false,
        "required": true,
        "icon": "fa-sort-numeric-up-alt",
        "description": "Age",
        "formControlName": "number",
        "leadRequired": "",
        "placeholder": "number",
        "className": "form-control",
        "value": "",
        "min": 12,
        "max": 90,
        "normalisateur": "",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
    },
    {
        "type": "date",
        "icon": "fa-calendar",
        "label": "Date",
        "specific": false,
        "required": true,
        "placeholder": "Date",
        "formControlName": "date",
        "leadRequired": "",
        "className": "form-control",
        "errorText": "Merci de choisir une date",
        "hide": false,
    },
    {
        "type": "datetime-local",
        "icon": "fa-calendar",
        "label": "DateTime",
        "formControlName": "datetime",
        "leadRequired": "",
        "specific": false,
        "required": true,
        "placeholder": "Date Time",
        "className": "form-control",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
    },
    {
        "type": "textarea",
        "icon": "fa-text-width",
        "formControlName": "textarea",
        "leadRequired": "",
        "label": "Textarea",
        "specific": false,
        "required": true,
        "normalisateur": "",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
    },
    {
        "type": "paragraph",
        "icon": "fa-paragraph",
        "specific": false,
        "required": true,
        "formControlName": "paragraph",
        "leadRequired": "",
        "label": "Paragraph",
        "text": "",
        "placeholder": "Type your text to display here only",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
    },
    {
        "type": "checkbox",
        "required": true,
        "label": "Checkbox",
        "specific": false,
        "icon": "fa-check-square",
        "formControlName": "checkbox",
        "leadRequired": "",
        "description": "Checkbox",
        "errorText": "Merci de choisir une date",
        "hide": false,
        "inline": true,
        "values": [
            {
                "label": "Option 1",
                "value": "option-1"
            },
            {
                "label": "Option 2",
                "value": "option-2"
            }
        ]
    },
    {
        "type": "radio",
        "icon": "fa-list-ul",
        "label": "Radio",
        "specific": false,
        "required": true,
        "description": "radio",
        "formControlName": "radio",
        "leadRequired": "",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
        "valueSelected": "",
        "values": [
            {
                "label": "Radio 1",
                "value": "Radio 2",
                "isChecked": true,
            },
            {
                "label": "Radio 2",
                "value": "Radio 2",
                "isChecked": true,
            }
        ]
    },

    {
        "type": "autocomplete",
        "icon": "fa-caret-square-down",
        "label": "Select",
        "specific": false,
        "required": true,
        "description": "Select",
        "placeholder": "Select",
        "formControlName": "autocomplete",
        "leadRequired": "",
        "className": "form-control",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
        "valueSelected": "",
        "values": [
            {
                "label": "Option 1",
                "value": "option-1"
            },
            {
                "label": "Option 2",
                "value": "option-2"
            },
            {
                "label": "Option 3",
                "value": "option-3"
            }
        ]
    },

    {
        "type": "autocomplete",
        "icon": "fa-caret-square-down",
        "label": "Traduction",
        "specific": false,
        "isTranslateInput": true,
        "required": false,
        "hide": true,
        "valueSelected": "Fr",
        "values": [
        ]
    },
    {
        "type": "autocomplete",
        "icon": "fa-caret-square-down",
        "label": "Traduction regex",
        "specific": false,
        "isTranslateRegexInput": true,
        "required": false,
        "hide": true,
        "valueSelected": "",
        "values": []
    },
    {
        "type": "autocomplete",
        "icon": "fa-caret-square-down",
        "label": "Charte",
        "specific": false,
        "isCharteInput": true,
        "required": false,
        "hide": true,
        "valueSelected": "",
        "values": []
    },
    {
        "type": "file",
        "icon": "fa-file",
        "specific": false,
        "required": true,
        "label": "File Upload",
        "className": "form-control",
        "formControlName": "file",
        "leadRequired": "",
        "subtype": "file",
        "errorText": "Merci de remplir ce champs",
        "hide": false,
    },
    {
        "type": "button",
        "icon": "fa-paper-plane",
        "specific": false,
        "hide": false,
        "subtype": "submit",
        "label": "Enregistrer"
    },

];
const eventInfo: Array<any> = [
    {
        "type": "typeEvent",
        "label": "Type de l'évènement",
        "formControlName": "type",
        "value": [
            {
                "label": "Evènement",
                "value": "Evènement"
            },
            {
                "label": "Pop-in leadform",
                "value": "Pop-in leadform"
            }
        ]
    },
    {
        "type": "subTypeEvent",
        "label": " Sous-type de l'évènement",
        "formControlName": "subType",
        "value": [
            {
                "label": "Salon",
                "value": "Salon"
            },
            {
                "label": "Jeux concours",
                "value": "Jeux concours"
            },
            {
                "label": " Offre commerciale",
                "value": " Offre commerciale"
            },
            {
                "label": "Street marketing",
                "value": "Street marketing"
            },
            {
                "label": "Showroom",
                "value": "Showroom"
            },
            {
                "label": "Pop-up store",
                "value": "Pop-up store"
            }
        ]
    },
    {
        "type": "perimetre",
        "label": "Périmètre de l'évènement",
        "formControlName": "perimetre",
        "value": [
            {
                "label": "International ",
                "value": "International "
            },
            {
                "label": "National",
                "value": "National"
            },
            {
                "label": "Régional",
                "value": "Régional"
            },
            {
                "label": "Local",
                "value": "Local"
            }
        ]
    },
];
const destinationOptions = ["Vendeur (à l’étude)", "Panier vendeurs", "CSMO", "HISTO", "PTF Locale (à l’étude)", "PTF Nationale"];
const listOptions = [
    {
        value: "Temporisation d’intégration des leads",
        formControlName: "optTempo",
        checked: false
    }, {
        value: "Notification center",
        formControlName: "notifCenterValue",
        checked: false
    }
   
];
const notifcenterOptions = [
    {label: 'sélectionnez un choix', value: 'placeHolder', checked: true},
    {label:"Notification Email", value:"Notification Email",checked: false,},
    {label:"Notification SMS", value:"Notification SMS",    checked: false,},
    { label:"Notification Email ou SMS", value:"Notification Email ou SMS",checked: false}
]
const smsEmailOptions = [
    {label: 'sélectionnez un choix', value: 'placeHolder', checked: true},
    {label:"Email", value:"Email",checked: false}, 
    {label:"SMS", value:"SMS",checked: false},

    
                        
                    ]
const listRegex = [
    {
        "name": "Telephone",
        "value": "^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$"
    },
    {
        "name": "Téléphone portable",
        "value": "^(?:(?:\\+|00)33|0)\\s*[6-7](?:[\\s.-]*\\d{2}){4}$"
    },
    {
        "name": "Téléphone fixe",
        "value": "^(?:(?:\\+|00)33|0)\\s*[1-5-9](?:[\\s.-]*\\d{2}){4}$"
    },
    {
        "name": "Nom ou Prénom",
        "value": "^.{2,}$"
    },
    {
        "name": "Email",
        "value": "^([a-z0-9_.-]+)@([a-z0-9_.-]+)\\.([a-z]{2,5})$"

    },
    {
        "name": "Code postal",
        "value": "^[0-9]{5}$"

    },


    {
        "name": "Siret",
        "value": "^[0-9]{14}$"

    },
    {
        "name": "aucun",
        "value": ""
    }
]
const listNormalizator = [
    {
        "name": "Nom Propre",
        "value": "lastname"
    },
    {
        "name": "MAJUSCULES",
        "value": "maj"
    },
    {
        "name": "minuscules",
        "value": "minus"
    },
    {
        "name": "aucun",
        "value": ""
    }
]

const listDealers = [{
    "type": "list-dealers",
    "label": "Liste dealers",
    "name": "rrf",
    "description": "rrf",
    "placeholder": "rrf",
    "required": true,
    "className": "form-control",
    "valueSelected": "",
    "values": [
        {
            "label": "RENAULT MASSY",
            "value": "00000001"
        },
        {
            "label": "RENAULT CHILLY MAZARIN VO - RRG",
            "value": "00000002"
        },
        {
            "label": "DACIA PARIS",
            "value": "00000003"
        }
    ]
}];
const oneDealer = [
    {
        "type": "un-dealer",
        "label": "code RRF",
        "description": "code RRF",
        "placeholder": "code RRF",
        "className": "form-control",
        "subtype": "text",
        "regex": "",
        "value": '',
        "errorText": "Merci de saisir votre type du text",
        "handle": true
    },
    {
        "type": "Nom-affire",
        "label": "Nom d'affaire",
        "description": "Nom d'affaire",
        "placeholder": "Nom d'affaire",
        "className": "form-control",
        "subtype": "text",
        "regex": "",
        "value": "",
        "errorText": "Merci de saisir votre type du text",
        "handle": true
    },
]

const temporisationTimesList = [
    {
        label: 'sélectionnez un choix',
        value: 'placeHolder',
        selected: true
    },

    {
        label: 'Génération du lead dans SFDC au bout de X heures',
        value: 'hours',
        selected: false
    },
    {
        label: 'Génération du lead dans SFDC à hh:mm',
        value: 'hourMinutes',
        selected: false
    },
    {
        label: 'Génération des leads le jj-mm-aaaa à hh:mm',
        value: 'dateHour',
        selected: false
    }
];

const notifEmailTemplateOptions = [
    {
        label: 'sélectionnez un choix',
        value: 'placeHolder',
        checked: true
    },
    {
        label: 'Template Renault',
        value: 'Template Renault',
        checked: false
    },
    {
        label: 'Template Dacia',
        value: 'Template Dacia',
        checked: false
    },
]
export { fieldModels, eventInfo, destinationOptions, listOptions, listRegex, listNormalizator, listDealers, oneDealer, temporisationTimesList,smsEmailOptions,notifcenterOptions,notifEmailTemplateOptions};
import { Component } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-charte',
  templateUrl: './charte.component.html',
  styleUrls: ['./charte.component.scss']
})
export class CharteComponent {
  modalRef?: BsModalRef;
  chartesList = [];
  charteId:number=null;
  isEdit: boolean = false;
  excelfileToUpload: File = null;
  public charteForm: FormGroup;
  config = {
    class: 'modal-md',  
    backdrop: true,
    ignoreBackdropClick: true
  };


  constructor(private datacatchingService: DatacatchingService,private modalService: BsModalService, private toastr: ToastrService, private formBuilder: FormBuilder,){
    this.charteForm = this.formBuilder.group({
      brand: [ "",[Validators.required]],
      // color: ["",[Validators.required]],
      // text_color:["",[Validators.required]],
      home_page_bg_color:["",[Validators.required]],
      home_page_inner_card_bg_color:["",[Validators.required]],
      btn_bg_color:["",[Validators.required]],
      btn_text_color :["",[Validators.required]],
      form_bg_color :["",[Validators.required]],
      submit_btn_disabled_color:["",[Validators.required]],
      submit_btn_enabled_color:["",[Validators.required]],
    });
  }

  async ngOnInit(){
    this.isEdit= false;
  let isTokenNotExpired =await this.datacatchingService.TokenValid();
  this.datacatchingService.getApi(`${ENV.API_URL}V2/charte`, isTokenNotExpired, 'normal').subscribe(charts=>{
   if(charts['success']){
    this.chartesList = charts['result']
   }
  
  })
 }
 onCancel() {
  this.modalRef.hide();
  this.charteForm.reset();
  this.isEdit = false;
}

onAdd(templateAdd, type, id) {
 this.charteForm.reset();
  this.isEdit= false
  this.modalRef = this.modalService.show(templateAdd,this.config );
  if (id !== null && type == "edit") {
    this.isEdit = true;
    let obj = this.chartesList.filter(charte => id === charte.id);
    this.charteId = obj[0].id; 
    this.charteForm.patchValue({  brand:obj[0].brand, home_page_bg_color:obj[0].home_page_bg_color,home_page_inner_card_bg_color:obj[0].home_page_inner_card_bg_color,btn_bg_color:obj[0].btn_bg_color,btn_text_color:obj[0].btn_text_color,form_bg_color:obj[0].form_bg_color,
                                submit_btn_disabled_color:obj[0].submit_btn_disabled_color,submit_btn_enabled_color:obj[0].submit_btn_enabled_color });
  }
}

get fields() {
  return this.charteForm.controls
}
async onSubmit() {
  let isTokenNotExpired = await this.datacatchingService.TokenValid();
  let url = `${ENV.API_URL}v2/charte`;

  for(let el of this.chartesList){
    if(el.brand.toUpperCase() === this.charteForm.getRawValue().brand.toUpperCase()){
      this.toastr.error('La marque exists déja');
      return;
    }
  }
  this.datacatchingService.postApi(url, this.charteForm.getRawValue(), isTokenNotExpired, 'normal').subscribe(res => {
    this.getCharte();
    this.toastr.success("Le charte ajouté avec succès");
    this.modalRef.hide();
    this.charteForm.reset();
  })
}
async onUpdate() {
  let isTokenNotExpired = await this.datacatchingService.TokenValid();
  let url = `${ENV.API_URL}v2/charte`;
  let obj = { ...this.charteForm.getRawValue(), id:this.charteId }
  this.datacatchingService.updateApi(url, obj, isTokenNotExpired).subscribe(res => {
    this.getCharte();
    this.toastr.success("Le charte mis à jour avec succès");
    this.modalRef.hide();
    this.charteForm.reset();
  })
}

async getCharte() {
  let isTokenNotExpired =await this.datacatchingService.TokenValid();
  let url = `${ENV.API_URL}v2/charte`;
  this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
    this.chartesList = res['result'];
  })
}
async onDelete() {
  let isTokenNotExpired =await this.datacatchingService.TokenValid();
  this.datacatchingService.deleteApi(`${ENV.API_URL}v2/charte/${this.charteId}`, isTokenNotExpired, 'normal').subscribe(res => {
    if (res['result']['rowCount'] === 1) {
      this.getCharte()
      this.modalRef.hide();
      this.toastr.success("Le charte a été supprimé avec succès");

    }
  })
}
onDeleteCharte(templateDelete, id) {
  this.charteId = id;
  this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MESSAGE } from '../../../core/message';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  authCode = '';


  constructor(private authService: AuthService,private toastr: ToastrService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.authCode = params['code'];

    });

  }

  ngOnInit() {
    this.getToken();
  }

  getToken() {
    if(this.authCode==null || this.authCode==undefined){
      sessionStorage.setItem("userIsAuthorized", "false");
      this.toastr.info(MESSAGE.MESSAGE_ACCES_INTERDIT);
      this.router.navigate(['']);
      return;
    } 

    this.authService.validateCode(this.authCode).subscribe(data => {
      var res = data as any;

      if (res.hasOwnProperty('access_token')) {
        this.authService.check_AccesRights(res);
      }
      else {
        console.log('erreur : ', res);
      }

    })
  }

}


<div class="app-conent content mt-5 pt-3">
    <div class="row pl-1 pr-0 mb-2">
        <div class="col text-left pl-0 pr-0 mb-2">
            <a class="mb-1 home-link" style="color:#1f2532" routerLink="/home">
                <i class="fa fa-arrow-left" style='color:#777;padding-right:10px;'></i>Retour à la accueil</a>
        </div>
        <div class="col-4 text-right pl-0 pr-0 mr-1 d-flex">
            <p class="remove-filter" (click)="clearFilter()" *ngIf="searchTerm.length"><i class="la la-remove"></i> </p>
            <input type="text rounded-0" class="form-control border-0" [(ngModel)]="searchTerm"
                placeholder="saisir le nom d'événement..." />
            <button class="btn btn-primary box-shadow-0" (click)="filterEvents()">Recherche</button>
        </div>
    
    </div>
    <div class="table-wrapper">
<table class="table table-striped mb-0" *ngIf="masterEvents.length>0">
    <thead>
        <tr>
            <th scope="col">N°</th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='date_creation' && order==='ASC' ? sortBy('date_creation','DESC') : sortBy('date_creation','ASC')">
                Date de création
                <i style="cursor:pointer;padding-right:0" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='date_creation' && order==='ASC'" (click)="sortBy('date_creation','ASC')"></i>
                <i style="padding-left: 2px; cursor:pointer ;padding-right:0" class="fas fa-arrow-up font-size-xsmall text-light"
                    (click)="sortBy('date_creation','DESC')" [class.text-dark]="field==='date_creation' && order==='DESC'"></i>
            
            </th>
            <th style="cursor:pointer" scope="col" scope="col"
                (click)="field==='event_name' && order==='ASC' ? sortBy('event_name','DESC') : sortBy('event_name','ASC')">
                Nom de l'événement
                <i style="cursor:pointer;padding-right:0" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='event_name' && order==='ASC'" (click)="sortBy('event_name','ASC')"></i>
                <i style="padding-left: 2px; cursor:pointer;padding-right:0" class="fas fa-arrow-up font-size-xsmall text-light"
                    (click)="sortBy('event_name','DESC')" [class.text-dark]="field==='event_name' && order==='DESC'"></i>
            </th>
            <th scope="col">Modifier</th>
            <th scope="col">Sélectionner</th>
            <th scope="col">Supprimer</th>
        </tr>
    </thead>
    <tbody *ngIf="masterEvents.length>0">
        <tr *ngFor="let event of masterEvents | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: total };let i=index;">
            <td>{{((p - 1) * 8 + (i+1))}}</td>
            <td style='color: #1f2532;'>{{transfromDate(event.date_creation)}}</td>
            <ng-template #tipContent> {{event.event_name}}</ng-template>
            <td style='color: #1f2532;' container="body" [ngbTooltip]="event.event_name?.length>25 ? tipContent : ''">
                {{(event.event_name?.length>25)? (event.event_name | slice:0:25)+'...':(event.event_name)}}
            </td>
            <td>
                <div class="link-icon" (click)="onTemplateEdit(event.code_event)">
                    <span class="text-warning cursor-pointer"><i class="la la-pencil"></i></span>
                </div>
            </td>
            <td>  
            <div class="col" (click)="onEdit(event.code_event)">
                <span class="text-success cursor-pointer"> <i class="la la-check"></i></span>
            </div>
            </td>
            <td>
                <div class="col" (click)="onDelete(event.code_event,event.event_obj.name)">
                    <span class="text-danger cursor-pointer">
                        <i class="la la-trash"></i>
                    </span>
                </div>
            </td>
        </tr>
    </tbody>
</table>

</div>
<div class="text-center mt-0" *ngIf="masterEvents.length>0">
    <div class="mt-0">
        <pagination-controls responsive="true" previousLabel="" nextLabel="" (pageChange)="pageChanged($event)">
        </pagination-controls>
    </div>
</div>
<p *ngIf="masterEvents.length==0 && isEmptyEvents" class="text-center pt-5">Votre recherche n'a retourné aucun résultat. Veuillez modifier les critères
    de recherche.</p>
</div>
        <div class="text-center" *ngIf="isLoading">
            <h5 class="text-center">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h5>
        </div>


import { Validators } from '@angular/forms';
const configForm = {
    optTempo: [null],
    notifCenterValue: [null],
    smsorEMailOption : [null],
    notifCenterTemplateValue:[null],
    optTempSelected: [null],
    dateTempo: [null],
    hourMinute: [null],
    dateHour: [null],
    redirection: [null, [Validators.required]]
}

const attributeForm = {
    leadSource: ["", [Validators.required]],
    platformBrand: ["", [Validators.required]],
    origin: ["", [Validators.required]],
    context: ["", [Validators.required]],
    device: ["", [Validators.required]],
    leadFormName: ["", [Validators.required]],
    subTypeOfInterest: ["", [Validators.required]],
    reminderResponsible: ["", [Validators.required]],
    leadProvider: ["", [Validators.required]],
    leadCountry: ["", [Validators.required]],
    campaign: ["", [Validators.required]],
    typeOfInterest: ["", [Validators.required]],
    dealerOfInterest: ["", [Validators.required]]
};

export { configForm, attributeForm };

<div class="app-content content mt-5 pt-5">
  <div class="container">
    <h3 class="text-center mb-2" id="intro-text"> CATCHY</h3>
    <div class="container pt-2 pb-5" style="background-color: #f3f5f6;">
      <div class="col-9" style="margin:0 auto;">
        <div class="row mt-2">
          <div class="wrapper" style="padding: 0 !important;" routerLink="/forms">
            <div class="icon">
              <div>
                <i class="la la-cubes" style="font-size: 3rem;"></i>
              </div>
            </div>
            <div class="text"> Liste des évènements</div>
          </div>
          <div class="wrapper" routerLink="/template" style="padding: 0 !important;">
            <div class="icon bars-container">
              <i class="icon-side fa fa-list-alt" style="font-size: 2rem;"></i>
            </div>
            <div class="text">
              Liste des templates
            </div>
          </div>
          <div class="wrapper" style="padding: 0 !important;" routerLink="/events">
            <div class="icon">
              <div>
                <i class="la la-plus-circle" style="font-size: 3rem;"></i>
              </div>
            </div>
            <div class="text">Créer un évènement</div>
          </div>
          <div class="wrapper mt-3" routerLink="/lead" style="padding: 0 !important;" data-toggle="modal"
            data-target="#default">
            <div class="icon bars-container">
              <i class="fa fa-chart-line" style="font-size: 2rem;"></i>
            </div>
            <div class="text">
              Liste des leads
            </div>
          </div>
          <div class="wrapper mt-3" routerLink="/lead-timing" style="padding: 0 !important;">
            <div class="icon bars-container">
              <i class="icon-side fas fa-hourglass-half" style="font-size: 2rem;"></i>
            </div>
            <div class="text">
              Leads en cours
            </div>
          </div>
          <div class="wrapper mt-3" routerLink="/lead-error" style="padding: 0 !important;">
            <div class="icon bars-container">
              <i class="icon-side fas fa-exclamation-triangle" style="font-size: 2rem;"></i>
            </div>
            <div class="text">
              Leads en erreur
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

</div>

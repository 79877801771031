import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ENV } from '../core/env.config';
import { User } from '../components/models/user';
import jwt_decode from 'jwt-decode';
import { MESSAGE } from '../core/message';
import { DatacatchingService } from './datacatching.service';

@Injectable()
export class AuthService {
  public rolesChyRenaultNet: string[] = ['IRN-72089:admin'];
  public appMainRenaultNetRole: string = "role-rnet-chy-irn72089";
  public appMainArcaRole: string = "role-chy-irn72089";
  public rolesChyAdminArca: string[] = ['chy_72089_admin'];

  public authorized: boolean;
  public user: User = {} as User;

  constructor(private router: Router, private http: HttpClient, private datacatchingService: DatacatchingService) {
  }


  public async check_AccesRights(res) {
    const id_token = res['id_token'];
    const acc_token = res['access_token'];
    const ref_token = res['refresh_token'];

    const profile = jwt_decode(id_token) as any;
    let appRoles = [];
    appRoles = profile.roles;
     let extractedRoles = this.extractRoles(appRoles)
    if (extractedRoles !== undefined && extractedRoles.length > 0  ) {
  
      const expires_at = profile['exp'];
      sessionStorage.setItem('id_token', id_token);
      sessionStorage.setItem('access_token', acc_token);
      sessionStorage.setItem('refresh_token', ref_token);
      sessionStorage.setItem('expires_at', expires_at);
      sessionStorage.setItem("userIsAuthorized", "true");

      if (profile.firstName !== undefined && profile.firstName !== null) {
        this.user.firstName = profile.firstName;
      }

      if (profile.lastName !== undefined && profile.lastName !== null) {
        this.user.lastName = profile.lastName;
      }
      this.user.uid = profile["login"]; 
      this.user.email = profile["mail"];
      this.user.scope = this.extractRoles(appRoles);
      let userObj = { date_creation: this.transfromDate(new Date().toLocaleString()), ...this.user };
      let isTokenNotExpired = await this.datacatchingService.TokenValid();
      this.datacatchingService.postApi(`${ENV.API_URL}v2/history/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
        if (res['id_token'] && res['id_token'] !== 'localhost') {
          sessionStorage.setItem('id_token', res['id_token']);
        }
      });
      sessionStorage.setItem("currentUser", JSON.stringify(this.user));
    } else {
      sessionStorage.setItem("userIsAuthorized", "false");
    }

    this.redirect();
  }

  extractRoles(rolesList) {
    const roles = [];
    for (let role of rolesList) {
      if (role.toLowerCase().includes('admin')){ roles.push('admin'); sessionStorage.setItem('profile', 'admin'); }
      if (role.toLowerCase().includes(('reporter').toLowerCase())){ roles.push('reporter'); }
    }
    return [...new Set(roles)];
   }

  transfromDate(date) {
    date = date.split('/').join('-').replace(',', '');
    let onlyDate = date.split(' ');
    let formatChangedDate = onlyDate[0].split('-');
    let d = `${formatChangedDate[2]}-${formatChangedDate[1]}-${formatChangedDate[0]}T${onlyDate[1]}`;
    console.log('date', d);
    
    return d;
  }

  getCurrentUser() {
    var userstr = sessionStorage.getItem("currentUser");
    var user = {} as User;

    if (userstr !== undefined && userstr !== "" && userstr !== "null" && userstr !== null) {
      user = JSON.parse(userstr);
    }

    return user;
  }

  public isAuthorized(): Boolean {
    let cond = sessionStorage.getItem("userIsAuthorized");
    let isAuth: boolean = false;

    if (cond === null) {
      isAuth = false;
    } else {
      isAuth = JSON.parse(cond)
    }
    return isAuth;
  }

  public isAdmin(): boolean {
    let profile = sessionStorage.getItem("profile");

    if (profile !== undefined && profile !== null && profile === 'admin') {
      return true;
    } else {
      return false;
    }
  }

  public redirect() {
    let redirect = decodeURI(sessionStorage.getItem('authRedirect'));
    if (redirect == null || redirect == "null") {
      redirect = '/home';
    }

    this.router.navigate([redirect]);
    // Redirection completed; clear redirect from storage
    this.clearRedirect();
  }

  public clearRedirect() {
    // Remove redirect from sessionStorage
    sessionStorage.removeItem('authRedirect');
  }

  public removeTokens() {
    sessionStorage.clear();
    localStorage.clear();
  }

  public logoff() {
    this.removeTokens();
    this.clearRedirect();
    this.router.navigate(['/']);
  }

  public login() {
    window.location.href = encodeURI(
      `${ENV.AUTH_DOMAIN}` + '?' +
      'response_type=code' +
      '&state=3gTy3bZJzgba0MYQcYTbQKsuKIfyCA7lHmRdbeFk'  +
      '&scope=openid offline_access alliance_profile' +
      '&redirect_uri=' + `${ENV.REDIRECT_URI}` +
      '&client_id=' + `${ENV.CLIENT_ID}`);
  }

  async TokenValid() {
    var now = Date.now() / 1000;
    var expires_at = JSON.parse(sessionStorage.getItem('expires_at'));
    if (expires_at == undefined || expires_at == null) {
      return false;
    }
    if (now > expires_at) {
      try {
        var refresh = sessionStorage.getItem('refresh_token');
        var refreshTokenReponse = await this.refreshToken(ENV.AUTH_URL + '/refresh_token?refresh_token=' + refresh, {}).toPromise() as any;
        if (refreshTokenReponse !== undefined && refreshTokenReponse !== null && refreshTokenReponse.access_token !== null) {
          var profile = jwt_decode(refreshTokenReponse.access_token);
          var expires_at = profile['exp'] as any;
          sessionStorage.setItem('expires_at', JSON.stringify(expires_at));
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log("error :", error)
        return false;
      }
    } else {
      return true;
    }
  }

  public validateCode(code) {
    return this.http.post(ENV.AUTH_URL, { code: code }, {});
  }

  refreshToken(urlPath, params) {
    return this.http.post(urlPath, params);
  }
}


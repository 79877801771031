import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service'
import { MESSAGE } from '../core/message';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const _isLocalhost = window.location.port.indexOf('4200') > -1

    if (_isLocalhost) {
      return true;
    }
    if (!this.authService.TokenValid()) {
      if (sessionStorage.getItem("isValide") != undefined && sessionStorage.getItem("isValide") != null) {
        this.toastr.info(MESSAGE.MESSAGE_ACCES_INTERDIT);
      }
      else {
        sessionStorage.setItem("isValide", "isValide");
      }
      sessionStorage.setItem('authRedirect', state.url);
      this.router.navigate(['/'], { queryParams: { 'status': 'login_required' }, skipLocationChange: true });
      return false;
    }

    if (!this.authService.isAuthorized()) {

      if (sessionStorage.getItem("isAuth") != undefined && sessionStorage.getItem("isAuth") != null) {
        this.toastr.info(MESSAGE.MESSAGE_ACCES_INTERDIT);
      }
      else {
        sessionStorage.setItem("isAuth", "isAuth");
      }

      sessionStorage.setItem('authRedirect', state.url);
      this.router.navigate(['/'], { queryParams: { 'status': 'access_denied' }, skipLocationChange: true });
      return false;
    }
    return true;
  }
}

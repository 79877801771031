import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ENV } from '../../core/env.config';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { decode } from 'html-entities';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DateAdapter, MAT_DATE_LOCALE,MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  fill: ApexFill;
  grid: ApexGrid;
  title: ApexTitleSubtitle,
  legend:ApexLegend
}
export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  grid: ApexGrid
};


@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },]
})
export class LeadComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @ViewChild("chart1") chart1: ChartComponent;
  public chartOptions1: Partial<ChartOptions1>;
  locale = 'fr';
  locales = listLocales();
  leadGraphObj = {};

   sfdc_url = ENV.SF_URL;
   sfdc_url_int = 'https://renaultarca--hquat.my.salesforce.com/';
   sfdc_url_prod = 'https://renaultarca.my.salesforce.com/';
   listLeads: any = [];
   tempLeads: any = [];
   p = 1;
   searchTerm = "";
   lead_id = 0;
   isMoreinfo?: boolean = false;
   leadObj;
   isLoading: boolean = false;
   attributesList = [];
   parentKeysList = [];
   selectedLead;
   formsInputs;
  formLablesList;
  total: number;
  itemsPerPage: number;
  modalRef?: BsModalRef;
  field: String ='date_creation';
  order: String = 'DESC';
  dateValue = '';
  data?: Date;
  bsRangeValue;
  bsRangeGraphValue;
  minDate = this.transformDates(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  maxDate = this.transformDates(new Date())
  leadTotalCount = 0;
  leadStartDate;
  leadEndDate;
  minDateLead: Date;
  maxDateLead: Date;
  isCollapsed = true;
  initalDates = [];
 config: PerfectScrollbarConfigInterface = {};

 range = new FormGroup({
  start: new FormControl<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1))),
  end: new FormControl<Date | null>(new Date()),
});
  
  constructor(private localeService: BsLocaleService, private datacatchingService: DatacatchingService, private datePipe: DatePipe, private modalService: BsModalService, private router: Router, private toastr: ToastrService) {
    this.localeService.use(this.locale);
    this.minDateLead = new Date();
    this.maxDateLead = new Date();
    this.minDateLead.setDate(this.minDateLead.getDate() - 30);
    this.maxDateLead.setDate(this.maxDateLead.getDate());
  };


  decodeValue(value) {
    return decode(value)
  }
  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyLeads(this.p, this.field, this.order);
    
  }
  clearDateRange() {
    this.bsRangeValue = '';
    // this.minDate = '';
    // this.maxDate = '';
    this.modifyLeads(1, this.field, this.order);
  }
  clearDateGraphRange() {
    this.bsRangeGraphValue = '';
    this.doCallsChartsForEvent("", "");
    
  }
  getDate(myDate) {
    return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  }

  onValueChange(value: Date): void {
    console.log('bsRangeValue;', this.bsRangeValue);
    // if (this.bsRangeValue !== undefined && this.bsRangeValue[0] !== undefined && this.bsRangeValue[1]!==undefined) {  
    //   this.modifyLeads(1, this.field, this.order);
    // } 
  }

  pageChanged(pageNum) {
    this.p = pageNum;
    this.modifyLeads(pageNum, this.field, this.order);
  }

  clearFilter() {
    this.searchTerm = '';
    this.modifyLeads(1, this.field, this.order);
  }

  onDateChange($event) {
    this.dateValue = $event.target.value;
    this.modifyLeads(1, this.field, this.order);
    
  }

  filterEvents() {
     this.modifyLeads(1, this.field, this.order);
  }

  modifyLeads(page, field, order) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.listLeads = [];
    this.isLoading = true;

    let url = `${ENV.API_URL}v2/leads/all?field=${field}&order=${order}&success=true&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/leads/all?field=${field}&order=${order}&success=true&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&term=${this.searchTerm}&size=8`;
    }
   
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(lead => {
      console.log('lead', lead);
      this.tempLeads = lead['leads'];
      this.total = lead['totalItems'];
      this.itemsPerPage = lead['itemsPerPage'];
      this.p = lead['currentPage'];
      if (this.tempLeads && this.tempLeads.length > 0) {
        this.tempLeads = lead['leads'];
        this.tempLeads = [...new Map(this.tempLeads.map(item => [(item['md5'] && item['lead_obj']), item])).values()];
        var i = 1;
        for (let lead of this.tempLeads) {
          var leadObj = new Object();
          leadObj['number'] = JSON.stringify((page - 1) * 8 + i);
          leadObj['id_db'] = lead.id;
          leadObj['id'] = lead.lead_obj?.leadInfo?.leadSubmissionId ? lead.lead_obj?.leadInfo.leadSubmissionId : null;
          leadObj['creationDate'] = lead.lead_obj?.leadInfo?.leadSubmissionTimestamp ? this.datePipe.transform(lead.lead_obj?.leadInfo?.leadSubmissionTimestamp, 'medium') : null;
          leadObj['lastName'] = lead.lead_obj?.client?.lastName ? lead.lead_obj?.client.lastName : null;
          leadObj['firstName'] = lead.lead_obj?.client?.firstName ? lead.lead_obj?.client.firstName : null;
          leadObj['mobilePhone'] = lead.lead_obj?.client?.mobilePhone ? lead.lead_obj?.client?.mobilePhone : null;
          leadObj['email'] = lead.lead_obj?.client?.email ? lead.lead_obj?.client?.email : null;
          leadObj['status'] = lead.succes ? 'création' : 'échec';
          leadObj['sfdc_id'] = lead.sfdc_id ? lead.sfdc_id : null;
          leadObj['sfdc_url'] = lead.lead_from == 'pwa' ? this.sfdc_url_prod : this.sfdc_url_int;
          leadObj['event_name'] = lead.event_name ? lead.event_name : null;
          this.listLeads.push(leadObj);
          i++;
        }
      }
      this.isLoading = false;
    })
  }
  truncate(input) {
    return input.length > 15 ? `${input.substring(0, 15)}...` : input;
  } 
  onValueChangeGraph($event) {
    if (this.bsRangeGraphValue !== undefined && this.bsRangeGraphValue[0] !== undefined && this.bsRangeGraphValue[1] !== undefined) {
      let minDate = this.getDate(new Date(this.bsRangeGraphValue[0]));
      let maxDate = this.getDate(new Date(this.bsRangeGraphValue[1]));
      this.doCallsChartsForEvent(minDate, maxDate);
    }
    
  }
  doCallsChartsForEvent(minDateGraph, maxDateGraph) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    minDateGraph == "" ? this.leadStartDate = this.initalDates[0] :  this.leadStartDate = minDateGraph;
    maxDateGraph == "" ? this.leadEndDate = this.initalDates[1] : this.leadEndDate = maxDateGraph;
    let url = `${ENV.API_URL}v2/leads/charts/events-month?minDateGraph=${minDateGraph}&maxDateGraph=${maxDateGraph}`;
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      console.log(' doCallsChartsForEvent', res);
      let data = res.results.eventsCountMonth.map(ev => ev.count.toString());
      let lables = res.results.eventsCountMonth.map(ev => ev.event_name);
      // second graph
      this.chartOptions = {
        series: [
          {
            name: "leads",
            data: data,
            color: '#fff'
          }
        ],

        chart: {
          type: "bar",
          height: 315,
          background: '#27a9b9',
          foreColor: '#fff',
          toolbar: {
            show: false,
          }
        },

        fill: {
          colors: ["#fff8ffcc", "#fff8ffcc"],
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: ["#fff8ffcc", "#fff8ffcc"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },

        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top", // top, center, bottomoffsetY: -20,
            },

            barHeight:  "25%",
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#000'
              }]
            }
          }

        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          offsetX: 20,
          style: {
            fontSize: '11px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: '400',
            colors: ["#000"]
          }
        },
        xaxis: {
          categories: lables,
          range: 5,
          decimalsInFloat: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true,
            trim: true,
            style: {
              colors: [],
              fontSize: '0',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        yaxis: {
          floating: true,
          decimalsInFloat: 0,
          axisBorder: {
            show: false
          },
          labels: {
            show: false,
            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },

          },
          tooltip: {
            enabled: true,

          }

        },
        grid: {
          show: false
        }

      }
    })
    
  }

  doCallsChartsByDate() {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/leads/charts`, isTokenNotExpired, 'normal').subscribe(res => {
      this.initalDates = [];
      this.leadEndDate = res.results.todayDate;
      this.leadStartDate = res.results.dateMonthBefore;
      this.initalDates.push( res.results.dateMonthBefore);
      this.initalDates.push(res.results.todayDate);
      this.leadTotalCount = res && res.results && res.results.totalCount ? res.results.totalCount : 0;
      let dates = res.results.datesList.map(d => this.datePipe.transform(d, 'dd/MM'))
      this.leadStartDate = res.results.datesList[0];
      this.leadEndDate = res.results.datesList[res.results.datesList.length - 1]
      console.log('doCallsChartsByDate', res);
      this.chartOptions1 = {
        series: [
          {
            name: "leads",
            data: res && res.results && res.results.numberOfLeadsPerDate ? res.results.numberOfLeadsPerDate : []
          }
        ],
        chart: {
          height: 300,
          type: "bar",
          foreColor: '#fff',
          background: '#44a149',
          toolbar: {
            show: false,
          }
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            },

            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#fff'
              }]
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "";
          },
        
          offsetY: -15,
          style: {
            fontSize: "11px",
            fontWeight: '400',
            colors: ["#fff"]
          },

        },

        xaxis: {
          labels: {
            rotate: 0,
            offsetY: 0,

            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 300,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          categories: dates ? dates : [],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#fff",
                colorTo: "#fff",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        fill: {
          colors: ["#fff8ffcc", "#fff8ffcc"],
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: ["#fff8ffcc", "#fff8ffcc"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + '';
            }
          }
        },
        title: {
          text: `Nombre de leads générés entre ${this.datePipe.transform(res.results.datesList[0], 'dd-MM-yyyy')} et ${this.datePipe.transform(res.results.datesList[res.results.datesList.length - 1], 'dd-MM-yyyy')}`,
          margin: 20,
          align: "center",
          style: {
            color: "#fff8ffcc",
            fontSize: "12px",
            fontWeight: "400"
          }
        },
      }

    })
    
  }
  
  ngOnInit() {
    this.doCallsChartsByDate();
    this.doCallsChartsForEvent("", "");
   
    this.modifyLeads(1, this.field, this.order); 
  }

  changeDate(d) {
    let splittedDate = d.split("T").join(' ');
    let date1 = splittedDate.slice(0, splittedDate.lastIndexOf("."));
    return date1;
  }

  moreInfo(id) {
    this.lead_id = id;
    let lead = this.tempLeads.filter((l: { id: any; }) => l.id == id);
    this.leadObj = lead[0];
    this.isMoreinfo = true;

  }

  async openModalEdit(templateEdit, id) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    let lead = this.tempLeads.filter(l => l.id == id);
    this.selectedLead = lead[0];
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    //let url1 = `${ENV.API_URL}v2/events/event/event_by_event_name/${encodeURIComponent(this.selectedLead['event_name']) }`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body,isTokenNotExpired,'normal').toPromise()
    //let res = await this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
       console.log('formLablesList', this.formLablesList);
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.lead_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
              } else {
                console.log('child Value', childValue)
                if (childValue !== null && Object.keys(childValue).length > 0) {
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[key]}.${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                    let label = this.formLablesList.filter(label => label.formControlName == grandChildKey);
                    this.formsInputs.push({ formControlName: `${[grandChildKey]}`, label: label.length ? label[0].formControlLabel : grandChildKey, parentKey: parentKey, value: grandChildValue })
                  }
                }
              }
            }
          }
        }
        // let campaignObj = this.formsInputs.filter()
        // let typeIntrestObj = 
        console.log(' this.attributesList', this.attributesList);
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }




  }

  gotoSalesforce() {

  }

  changeFormateDate(d) {
    let formatedDate;
    let date = d.split('T');
    let removeZone = date[1].split(".");
    formatedDate = date[0] + " " + removeZone[0];
    return formatedDate;
  }

  copyInputMessage(textToCopy) {
    const create_copy = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', textToCopy);
      e.preventDefault();
    };
    document.addEventListener('copy', create_copy);
    document.execCommand('copy');
    document.removeEventListener('copy', create_copy);
    this.toastr.success("Lead submission Id est copié")
  }

  OnSelect(id) {
    this.router.navigate(['/lead-details', id])
  }

  checkKey(key) {
    let list = this.formsInputs.filter(formInput => formInput.parentKey === key);
    return list.length ? false : true;
  }

  onStartDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
  }
  transformDates(date) {
    return new Date(new Date(date).toUTCString()).toISOString().split("T")[0];
  }
  onEndDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value !== null) {
      let start = new Date(this.range.getRawValue().start);
      start.setDate(start.getDate() + 1);
      let end = new Date(this.range.getRawValue().end);
      end.setDate(end.getDate() + 1);
      console.log("min date",this.transformDates(start));
      console.log("max date",this.transformDates(end));

      this.minDate = this.transformDates(start);
      this.maxDate =this.transformDates(end);
      this.modifyLeads(1, this.field, this.order);
    }
   
  }

}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ENV } from 'src/app/core/env.config';
import { DatacatchingService } from 'src/app/services/datacatching.service';

@Component({
  selector: 'app-translation-regex',
  templateUrl: './translation-regex.component.html',
  styleUrls: ['./translation-regex.component.css']
})
export class TranslationRegexComponent {
  listRegex = [
    {
      "name": "Telephone",
      "value": "^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$"
    },
    {
      "name": "Téléphone portable",
      "value": "^((06)|(07))[0-9]{8}$"
    },
    {
      "name": "Téléphone fixe",
      "value": "^(?:(?:\\+|00)33|0)\\s*[1-5-9](?:[\\s.-]*\\d{2}){4}$"
    },
    {
      "name": "Nom ou Prénom",
      "value": "^.{2,}$"
    },
    {
      "name": "Email",
      "value": "^([a-z0-9_.-]+)@([a-z0-9_.-]+)\\.([a-z]{2,5})$"
    },
    {
      "name": "Code postal",
      "value": "^[0-9]{5}$"
    },
    {
      "name": "Siret",
      "value": "^[0-9]{14}$"

    },
    {
      "name": "Autre",
      "value": ""
    }
  ]

  regexForm: FormGroup;
  modalRef?: BsModalRef;
  isEdit: boolean = false;
  languageId: string = '';
  config = {
    class: 'modal-lg',
    backdrop: true,
    ignoreBackdropClick: true
  };
  translations: any = [];
  idToEdit: string;
  translationObjInit = {
    country: "",
    telephone: "^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$",
    telephonePortable: "^((06)|(07))[0-9]{8}$",
    telephoneFixe: "^(?:(?:\\+|00)33|0)\\s*[1-5-9](?:[\\s.-]*\\d{2}){4}$",
    nomOuPrenom: "^.{2,}$",
    email:"^([a-z0-9_.-]+)@([a-z0-9_.-]+)\\.([a-z]{2,5})$",
    codePostal: "^[0-9]{5}$",
    siret: "^[0-9]{14}$",
    autre:""
   
  };


  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, private datacatchingService: DatacatchingService) {
    this.regexForm = this.formBuilder.group({
      country: [null, Validators.required],
      telephone: ["", Validators.required],
      telephonePortable: ["", Validators.required],
      telephoneFixe: ["", Validators.required],
      nomOuPrenom: ["", Validators.required],
      email: ["", Validators.required],
      codePostal: ["", Validators.required],
      siret: ["", Validators.required],
      autre: [""],
      
    });

    this.getTranlstions();
  }
  async getTranlstions() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/regex/translations`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      this.translations = res['translations'];
      console.log('regex translations', res);
    })
  }
  onCancel() {
    this.modalRef.hide();
    this.regexForm.reset();
    this.isEdit = false;
  }

  onAddLanguage(templateAdd, type, id) {
    if (id !== null && type == "edit") {
      this.isEdit = true;
      let translationObj = this.translations.filter(t => id === t.id);
      this.idToEdit = translationObj[0].id;
      this.regexForm.patchValue({ ...translationObj[0].regex_obj });
    }

    if (type == "add") {
        this.regexForm.patchValue({ ...this.translationObjInit });
    }
    this.modalRef = this.modalService.show(templateAdd, this.config);

  }
  get fields() {
    return this.regexForm.controls
  }
  onDeleteLanguage(templateDelete, id) {
    this.languageId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });

  }

  async onSubmit() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.postApi(`${ENV.API_URL}v2/regex/translation`, this.regexForm.getRawValue(), isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['success']) {
        this.getTranlstions();
        this.modalRef.hide();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    })

  }
  async onDelete() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/regex/translation/${this.languageId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['success']) {
        this.translations = this.translations.filter(t => t.id !== this.languageId);
        this.modalRef.hide();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    })
  }
  async onUpdate() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.updateApi(`${ENV.API_URL}v2/regex/translation/${this.idToEdit}`, this.regexForm.getRawValue(), isTokenNotExpired).subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['success']) {
        this.translations = this.translations.filter(t => t.id !== this.languageId);
        this.modalRef.hide();
        this.getTranlstions();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    })

  }

}

const _isLocalhost = window.location.port.indexOf('4200') > -1;
const _isDev = window.location.origin.indexOf('app-chy.gke.dev.gcp.renault.com') > -1;
const _isRe7 = window.location.origin.indexOf('app-chy.gke.int.gcp.renault.com') > -1;
const _isProd = window.location.origin.indexOf('chy.dcs2.renault.com') > -1;

const isLocalshost = () => {
  if (_isLocalhost) {
    return true;
  } else {
    return false;
  }
};

const getUrlApi = () => {
   //return _isLocalhost ? 'http://localhost:3000/api/' : '/api/';
  return _isLocalhost ? 'https://api-chy.gke.int.gcp.renault.com/api/' : '/api/';
};

const getUrlAuth = () => {
  return _isLocalhost ? 'http://localhost:3000/api/auth' : '/api/auth';
};

const getClientId = () => {
  return _isProd ? 'irn-72089_ope_code_gh5wym2t71nd' : 'irn-72089_uat_code_nucdsb50bsml';
};

const getSfdcUrl = () => {

  if (_isProd) {
    return 'https://renaultarca.my.salesforce.com/';
  }
  else {
    return 'https://renaultarca--hquat.my.salesforce.com/';
  }
};


const getRedirectUrl = () => {
  if (_isLocalhost) { return 'http://localhost:4200/auth_callback'; }
  if (_isDev) { return 'https://app-chy.gke.dev.gcp.renault.com/auth_callback'; }
  if (_isRe7) { return 'https://app-chy.gke.int.gcp.renault.com/auth_callback'; }
  if (_isProd) { return 'https://chy.dcs2.renault.com/auth_callback'; }
};

const getAuthDomain = () => {
  return _isProd ? 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize' : 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize';
};

const getPwaredirectUrl = () => {
  if (_isLocalhost) {
    return 'http://localhost:4200';
  }
  if (_isDev) {
    return 'https://pwa-chy.gke.dev.gcp.renault.com';
  }
  if (_isRe7) {
    return 'https://pwa-chy.gke.int.gcp.renault.com';
  }
  if (_isProd) {
    return 'https://pwa-chy.gke.ope.gcp.renault.com';
  }
};


export const ENV = {
  CLIENT_ID: getClientId(),
  REDIRECT_URI: getRedirectUrl(),
  API_URL: getUrlApi(),
  AUTH_DOMAIN: getAuthDomain(),
  AUTH_URL: getUrlAuth(),
  PWA_URL: getPwaredirectUrl(),
  SF_URL: getSfdcUrl(),
  IS_LOCALHOST: isLocalshost()
};

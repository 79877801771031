<ng-template #modal let-modal>
  <div *ngIf="loading">
  <div class="modal-header">
      <h4 class="modal-title">Lancement du traitement</h4>
      <button type="button" class="close" aria-label="Close"  (click)="modal.dismiss('Cross click')" >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Close</button>
    </div>
  </div>
</ng-template>
<div class="login-form" id="login-form">
    <form>
        <div class="avatar">
            <img src="../../assets/images/avatarFace.png" alt="Avatar" />
        </div>
        <h2 class="text-center">Catchy</h2>
        <div class="form-group">
            <button class="btn btn-primary btn-lg btn-block login-btn" (click)="connect()">Connexion utilisateur <i class="las la-lock-open"></i></button>
        </div>
    </form>
</div>
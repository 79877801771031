
<div class="app-conent content mt-5 pt-3 mb-2">
    <div class="w-25 ml-auto mb-1 text-right">
    <button type="button" class="btn btn-primary btn-primary-r mr-1" (click)="onAdd(templateAdd,'add', null)"><i class="fas fa-plus" style="padding-right: 5px;"></i> Ajouter un nouveau</button>
    </div>
    <div class="table-wrapper m-1 mb-2">

 
   
<table class="table bg-white table-striped">
    <thead>
        <tr>
            <th scope="col">N°</th>
            <th scope="col">Marque</th>
            <th scope="col">Texte couleur de bouton</th>
            <th scope="col">Couleur de l'arrière de bouton</th>
         
           <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let color of chartesList;let i=index">
            <td>{{i+1}}</td>
            <td>{{color.brand}}</td>
            <td>{{color.btn_text_color}}</td>
            <td>{{color.btn_bg_color}}</td>
         
            <td><ng-template #tipContentEdit>Modifier le code couleur</ng-template>
            <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit" (click)="onAdd(templateAdd, 'edit',color.id)">
                <i class="fas fa-pen"></i>
            </button>
            <ng-template #tipContentDelete>Suprrimer le code couleur</ng-template>
            <button type="button" class="btn btn-danger btn-sm mr-1" (click)="onDeleteCharte(templateDelete,color.id)" [ngbTooltip]="tipContentDelete"
                >
                <i class="fas fa-trash"></i>
            </button></td>
        </tr>
    </tbody>
</table>
</div>
<ng-template #templateAdd>
    <div class="modal-header border-0 text-white text-center bg-white p-0 pr-1 pt-1">
        <!-- <h4 class="modal-title text-white ml-auto text-center">Ajouter le code couleurs</h4> -->
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden" style="color: black !important">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
    <div>
        <form class="mt-2" [formGroup]="charteForm">
            <div class="form-group text-left mb-0">
                <label for="color">Marque <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.brand.touched && fields.brand.invalid ,'success-class':fields.brand.touched && fields.brand.valid }"
                    id="color" formControlName="brand" class="form-control" name="brand" required>
            </div>
            <div *ngIf="fields.brand.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.brand.touched && fields.brand.errors?.required" class="error-msg">Le marque
                        obligatoire</span>
                    <span
                        *ngIf="(fields.brand.touched && fields.brand.invalid) || (fields.brand.touched && fields.brand.errors?.pattern) "
                        class="error-msg">
                        Entrer un marque valide
                    </span>
                </p>
            </div>
           
            <div class="form-group text-left mb-0">
                <label for="color">Couleur de l’arrière-plan de la page accueil <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.home_page_bg_color.touched && fields.home_page_bg_color.invalid ,'success-class':fields.home_page_bg_color.touched && fields.home_page_bg_color.valid}"
                    id="color" formControlName="home_page_bg_color" class="form-control" name="text_color" required>
            </div>
            <div *ngIf="fields.home_page_bg_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.home_page_bg_color.touched && fields.home_page_bg_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.home_page_bg_color.touched && fields.home_page_bg_color.invalid) || (fields.home_page_bg_color.touched && fields.home_page_bg_color.errors?.pattern)"
                        class="error-msg">
                        Entrer un code couleur valide
                    </span>
                </p>
            </div>
            <div class="form-group text-left mb-0">
                <label for="color"> Couleur de l’encadré du bouton de la page d’accueil <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.home_page_inner_card_bg_color.touched && fields.home_page_inner_card_bg_color.invalid ,'success-class':fields.home_page_inner_card_bg_color.touched && fields.home_page_inner_card_bg_color.valid}"
                    id="color" formControlName="home_page_inner_card_bg_color" class="form-control" name="text_color" required>
            </div>
            <div *ngIf="fields.home_page_inner_card_bg_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.home_page_inner_card_bg_color.touched && fields.home_page_inner_card_bg_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.home_page_inner_card_bg_color.touched && fields.home_page_inner_card_bg_color.invalid) || (fields.home_page_inner_card_bg_color.touched && fields.home_page_inner_card_bg_color.errors?.pattern)"
                        class="error-msg">
                        Entrer un code couleur valide
                    </span>
                </p>
            </div>
            <div class="form-group text-left mb-0">
                <label for="color"> Couleur du bouton <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.btn_bg_color.touched && fields.btn_bg_color.invalid ,'success-class':fields.btn_bg_color.touched && fields.btn_bg_color.valid}"
                    id="color" formControlName="btn_bg_color" class="form-control" name="text_color" required>
            </div>
            <div *ngIf="fields.btn_bg_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.btn_bg_color.touched && fields.btn_text_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.btn_bg_color.touched && fields.btn_bg_color.invalid) || (fields.btn_bg_color.touched && fields.btn_bg_color.errors?.pattern)"
                        class="error-msg">
                         Entrer un code couleur valide
                    </span>
                </p>
            </div>
            <div class="form-group text-left mb-0">
                <label for="color">Couleur du texte du bouton<span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.btn_text_color.touched && fields.btn_text_color.invalid ,'success-class':fields.btn_text_color.touched && fields.btn_text_color.valid}"
                    id="color" formControlName="btn_text_color" class="form-control" name="text_color" required>
            </div>
            <div *ngIf="fields.btn_text_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.btn_text_color.touched && fields.btn_text_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.btn_text_color.touched && fields.btn_text_color.invalid) || (fields.btn_text_color.touched && fields.btn_text_color.errors?.pattern)"
                        class="error-msg">
                         Entrer un code couleur valide
                    </span>
                </p>
            </div>
            <div class="form-group text-left mb-0">
                <label for="color">  Couleur de l’arrière-plan du formulaire <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.form_bg_color.touched && fields.form_bg_color.invalid ,'success-class':fields.form_bg_color.touched && fields.form_bg_color.valid}"
                    id="color" formControlName="form_bg_color" class="form-control" name="text_color" required>
            </div>
            <div *ngIf="fields.form_bg_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.form_bg_color.touched && fields.form_bg_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.form_bg_color.touched && fields.form_bg_color.invalid) || (fields.form_bg_color.touched && fields.form_bg_color.errors?.pattern)"
                        class="error-msg">
                         Entrer un code couleur valide
                    </span>
                </p>
            </div>                
            <div class="form-group text-left mb-0">
                <label for="color"> Couleur du bouton « soumettre » non actif  <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.submit_btn_disabled_color.touched && fields.submit_btn_disabled_color.invalid ,'success-class':fields.submit_btn_disabled_color.touched && fields.submit_btn_disabled_color.valid}"
                    id="color" formControlName="submit_btn_disabled_color" class="form-control" name="submit_btn_disabled_color" required>
            </div>
            <div *ngIf="fields.submit_btn_disabled_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.submit_btn_disabled_color.touched && fields.submit_btn_disabled_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.submit_btn_disabled_color.touched && fields.submit_btn_disabled_color.invalid) || (fields.submit_btn_disabled_color.touched && fields.submit_btn_disabled_color.errors?.pattern)"
                        class="error-msg">
                         Entrer un code couleur valide
                    </span>
                </p>
            </div>
            <div class="form-group text-left mb-0">
                <label for="color">Couleur du bouton « soumettre » actif <span class="star">*</span></label>
                <input type="text" spellcheck="false"
                    [ngClass]="{ 'error':fields.submit_btn_enabled_color.touched && fields.submit_btn_enabled_color.invalid ,'success-class':fields.submit_btn_enabled_color.touched && fields.submit_btn_enabled_color.valid}"
                    id="color" formControlName="submit_btn_enabled_color" class="form-control" name="submit_btn_enabled_color" required>
            </div>
            <div *ngIf="fields.submit_btn_enabled_color.errors">
                <p class="text-left mb-0">
                    <span *ngIf="fields.submit_btn_enabled_color.touched && fields.submit_btn_enabled_color.errors?.required" class="error-msg">Ce champs est obligatoire</span>
                    <span
                        *ngIf="(fields.submit_btn_enabled_color.touched && fields.submit_btn_enabled_color.invalid) || (fields.submit_btn_enabled_color.touched && fields.submit_btn_enabled_color.errors?.pattern)"
                        class="error-msg">
                         Entrer un code couleur valide
                    </span>
                </p>
            </div>
           
           
    </form>
    <div class="d-flex justify-content-between mt-3">
        <div>
            <button type="button" class="btn btn-light text-secondary border-0 rounded-0" (click)="onCancel()"> <i class="fas fa-times" style="padding-right: 5px;"></i>Annuler</button>
        </div>
        <div> 
        <button *ngIf="!isEdit"   class="btn btn-primary btn-primary-r border-0"  [disabled]="!charteForm.valid" (click)="onSubmit()"> <i class="fas fa-save" style="padding-right: 5px;"></i>Enregistrer</button>
        <button *ngIf="isEdit" class="btn btn-primary btn-primary-r border-0"  [disabled]="!charteForm.valid" (click)="onUpdate()"> <i class="fas fa-save"  style="padding-right: 5px;"></i>Mis à jour</button>
        </div>
    </div>
    </div>
    </div>
</ng-template>


<ng-template #templateDelete>
    <div class="modal-header border-0 text-white text-center bg-danger">
        <h4 class="modal-title text-white ml-auto text-center">Supprimer le Charte</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2  edit-lead-modal-body">
        <p class="text-center">Voulez-vous supprimer le charte?</p>
        <div class="d-flex justify-content-between">

            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                    style="padding: 0.4rem 1rem !important;">Non</button>
            </div>
            <div>
                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
            </div>
        </div>
    </div>
</ng-template>

</div>

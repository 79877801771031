import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { FormsComponent } from './components/event/event.component';
import { NavComponent } from './components/nav/nav.component';
import { UserComponent } from './components/user/user.component';
import { EventComponent } from './components/create-event/create-event.component';
import { BaseComponent } from './components/base/base.component';
import { LeadComponent } from './components/lead/lead.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorComponent } from './components/error/error.component';
import { CallbackComponent } from './components/auth/callback/callback.component';
import { AuthGuard } from './guard/auth.guard';
import { LeadTimingComponent } from './components/lead-timing/lead-timing.component';
import { LeadErrorComponent } from './components/lead-error/lead-error.component';
import { TemplateComponent } from './components/template/template.component';
import { MailAlertComponent } from './components/mail-alert/mail-alert.component';
import { HistoryComponent } from './components/history/history.component';
import { TranslationComponent } from './components/translation/translation.component';
import { CharteComponent } from './components/charte/charte.component';
import { CountryComponent } from './components/country/country.component';



const routes: Routes = [
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: 'auth_callback', component: CallbackComponent },
  {
    path: "", component: NavComponent, canActivate: [AuthGuard], children: [
      { path: "home", component: BaseComponent },
      { path: "events", component: EventComponent },
      { path: "forms", component: FormsComponent },
      { path: "user/:id", component: UserComponent },
      { path: "lead", component: LeadComponent },
      { path: "lead-error", component: LeadErrorComponent },
      { path: "lead-timing", component: LeadTimingComponent },
      { path: "template", component: TemplateComponent },
      { path: "lead-error-mail-alert", component: MailAlertComponent },
      { path: "historique", component: HistoryComponent },
      { path:"translation", component:TranslationComponent},
      { path:"charte", component:CharteComponent},
      {path:"country", component:CountryComponent}
    ],
  },
  { path: "error", component: ErrorComponent },
  { path: "**", component: NotFoundComponent }


];


@NgModule({

  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],

  exports: [RouterModule]
})
export class AppRoutingModule { }

import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ENV } from 'src/app/core/env.config';
import { DatacatchingService } from 'src/app/services/datacatching.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  total: number;
  itemsPerPage: number;
  field: String = 'date_creation';
  order: String = 'DESC';
  dateValue = '';
  data?: Date;
  bsRangeValue;
  minDate = '';
  maxDate = '';
  p = 1;
  searchTerm = "";
  listHistory: any = [];
  tempHistory: any = [];
  isLoading: boolean = false;
  isFirstTab: boolean = true;
  isSecondTab: boolean = false;

  constructor(private datacatchingService: DatacatchingService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.modifyHistory(1, this.field, this.order);
  }
 
  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyHistory(this.p, this.field, this.order);

  }
  modifyHistory(page, field, order) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.isLoading = true;
    this.listHistory = [];
    let url = `${ENV.API_URL}v2/history/all?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/history/all?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&term=${this.searchTerm}&size=8`;
    }

    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      console.log('res history', res);
      this.tempHistory = res['history'];
      this.total = res['totalItems'];
      this.itemsPerPage = res['itemsPerPage'];
      this.p = res['currentPage'];
      if (this.tempHistory && this.tempHistory.length > 0) {
        this.tempHistory = res['history'];
        var i = 1;
        for (let history of this.tempHistory) {
          var historyObj= new Object();
          historyObj['number'] = JSON.stringify((page - 1) * 8 + i);
          historyObj['id'] = history.id ? history.id : null;
          historyObj['creationDate'] = history.date_creation ? history.date_creation : null;
          historyObj['last_name'] = history.last_name ? history.last_name : null;
          historyObj['first_name'] = history.first_name ? history.first_name : null;
          historyObj['email'] = history.email ? history.email : null;
          historyObj['ipn'] = history.ipn ? history.ipn : null;
          this.listHistory.push(historyObj);
          i++;
        }
      }
      this.isLoading = false;
    })
  }
  changeTab() {
    this.isFirstTab = !this.isFirstTab;
    this.isSecondTab = !this.isSecondTab;
  }
  
  clearDateRange() {
    this.bsRangeValue = '';
    this.minDate = '';
    this.maxDate = '';
    this.modifyHistory(1, this.field, this.order);
  }
  getDate(myDate) {
    return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  }

  onValueChange(value: Date): void {
    console.log('bsRangeValue;', this.bsRangeValue);
    if (this.bsRangeValue !== undefined && this.bsRangeValue[0] !== undefined && this.bsRangeValue[1] !== undefined) {
      this.minDate = this.getDate(new Date(this.bsRangeValue[0]));
      this.maxDate = this.getDate(new Date(this.bsRangeValue[1]));
      this.modifyHistory(1, this.field, this.order);
    }

  }
  changeFormateDate(d) {

    let formatedDate;
    let date = d.split('T');
    let removeZone = date[1].split(".");
    formatedDate = date[0] + " " + removeZone[0];
    return formatedDate;
  }



  pageChanged(pageNum) {
    this.p = pageNum;
    this.modifyHistory(pageNum, this.field, this.order);
  }

  clearFilter() {
    this.searchTerm = '';
    this.modifyHistory(1, this.field, this.order);
  }

  onDateChange($event) {
    this.dateValue = $event.target.value;
    this.modifyHistory(1, this.field, this.order);

  }

  filterEvents() {
    this.modifyHistory(1, this.field, this.order);
  }

}

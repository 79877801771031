
const leadsInputsList = [
    {
        "type": "leadCountry",
        "icon": "fa-caret-square-down",
        "label": "Pays",
        "specific": false,
        'isLead': true,
        "required": true,
        "formControlName": "leadCountry",
        "leadRequired": "leadInfo",
        "description": "Pays",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "France",
                "value": "France"
            }
        ]
    },
    {
        "type": "marque-lead",
        "icon": "fa-caret-square-down",
        "label": "Marque constructeur",
        //"specific": true,
        "description": "marque",
        "placeholder": "marque",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "platformBrand",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "RENAULT",
                "value": "RENAULT",
            },
            {
                "label": "DACIA",
                "value": "DACIA",
            },
            {
                "label": "ALPINE",
                "value": "ALPINE",
            }
        ]
    },
    {
        "type": "source",
        "icon": "fa-caret-square-down",
        "label": "Canal",
        //"specific": true,
        "description": "source",
        "placeholder": "source",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "leadSource",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "DATACATCHING",
                "value": "DATACATCHING",
            },
            {
                "label": "INTERNET",
                "value": "INTERNET",
            },
            {
                "label": " EVENT",
                "value": "Events",
            },
            {
                "label": "CAMPAGNE",
                "value": "Campaign",
            },
            {
                "label": "DEALER",
                "value": "Dealer",
            },
            {
                "label": "LOCAL",
                "value": "Local",
            },
        ]
    },
    {
        "type": "demande-lead",
        "icon": "fa-caret-square-down",
        "label": "Type d'intérêt",
        "specific": true,
        "required": true,
        "description": "demande",
        "placeholder": "demande",
        "name": "type de demande",
        'isLead': true,
        "className": "form-control",
        "formControlName": "typeOfInterest",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "VN",
                "value": "VN"
            },
            {
                "label": "VO",
                "value": "VO"
            },
            {
                "label": "APV",
                "value": "APV"
            }
        ]
    },
    {
        "type": "subType",
        "icon": "fa-caret-square-down",
        "label": "Sous-type d'intérêt",
        "description": "subType",
        "placeholder": "subType",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "subTypeOfInterest",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "Demande d'essai",
                "value": "TestDrive Request",
            },
            {
                "label": "Demande de brochure",
                "value": "Brochure Request",
            },
            {
                "label": "Demande de ebrochure",
                "value": "eBrochure Request",
            },
            {
                "label": "Demande de devis",
                "value": "Quote request",
            },
            {
                "label": "Demande de reprise",
                "value": "Trade in request",
            },
            {
                "label": "Demande atelier",
                "value": "Service request",
            },
            {
                "label": "Demande d'achat avec financement",
                "value": "Finance request",
            },
            {
                "label": "Demande d'achat",
                "value": "Purchase request",
            },
            {
                "label": "Demande d'accessoire",
                "value": "Accessory request",
            },
            {
                "label": "Demande d'information",
                "value": "General request",
            },
            {
                "label": "Reveal",
                "value": "Reveal",
            },
            {
                "label": "Newsletter",
                "value": "Newsletter",
            },
            {
                "label": "Jeux",
                "value": "Game",
            },
            {
                "label": "Parrainage",
                "value": "Sponsorship",
            },
            {
                "label": "Pop-up store",
                "value": "Pop-up store",
            },
        ]
    },
    {
        "type": "context",
        "icon": "fa-caret-square-down",
        "label": "Contexte",
        "description": "context",
        "placeholder": "context",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "context",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "catchy-leadform",
                "value": "catchy-leadform",
            },
            {
                "label": "catchy-event",
                "value": "catchy-event",
            }
        ]
    },
    {
        "type": "origin",
        "icon": "fa-caret-square-down",
        "label": "Origine (Sous-canal)",
        //"specific": true,
        "description": "origin",
        "placeholder": "origin",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "origin",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "crm",
                "value": "Crm",
            },
            {
                "label": "direct",
                "value": "Direct",
            },
            {
                "label": "liens-commerciaux",
                "value": "Commercial links",
            },
            {
                "label": "no-cookie",
                "value": "No-cookie",
            },
            {
                "label": "referencement-naturel",
                "value": "Natural Referencement",
            },
            {
                "label": "pub-internet",
                "value": "Pub-internet",
            },
            {
                "label": "sites-externes",
                "value": "External sites",
            },
            {
                "label": "emailing-no-paid",
                "value": "Emailing-no-paid",
            },
            {
                "label": "social-no-paid",
                "value": "Social-no-paid",
            },
            {
                "label": "emailing-paid",
                "value": "Emailing-paid",
            },
            {
                "label": "social-paid",
                "value": "Social-paid",
            },
            {
                "label": "adexchange",
                "value": "Adexchange",
            },
            {
                "label": "non-defini",
                "value": "Non-defini",
            },
            {
                "label": "prm",
                "value": "prm",
            },
            {
                "label": "Off-line",
                "value": "off-line",
            },
            {
                "label": "Hostess-collection",
                "value": "hotesses-collecte",
            },
            {
                "label": "Bornes-collecte",
                "value": "Collection terminals",
            },
            {
                "label": "Showroom",
                "value": "Show Room",
            },
            {
                "label": "Salon / Evénement",
                "value": "Show / Event",
            },
        ]
    },
    {
        "type": "campaign",

        "icon": "fa-caret-square-down",
        "label": "Campagne",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "campaign",
        "leadRequired": "leadInfo",
        "description": "Campagne",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "campaign",
                "value": "spring-tour-leclerc-op-dcf"
            }
        ]
    },
    {
        "type": "device",
        "icon": "fa-caret-square-down",
        "label": "Device",
        "description": "device",
        "placeholder": "device",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "device",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "Ordinateur",
                "value": "desktop",
            },
            {
                "label": "Tablette",
                "value": "tablet",
            },
            {
                "label": "Mobile",
                "value": "mobile",
            },
            {
                "label": "non-defini",
                "value": "undefined",
            }
        ]
    },
    {
        "type": "leadProvider",
        "icon": "fa-caret-square-down",
        "label": "CreationOrigin",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "leadProvider",
        "leadRequired": "leadInfo",
        "description": "Fournisseur",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "valueSelected": "null",
        "values": [
            {
                "label": "CATCHY",
                "value": "CATCHY"
            }
        ]
    },
    {
        "type": "formName",
        "icon": "fa-caret-square-down",
        "label": "Leadform Name",
        //"specific": true,
        "description": "formName",
        "placeholder": "formName",
        "required": true,
        'isLead': true,
        "className": "form-control",
        "formControlName": "leadFormName",
        "leadRequired": "leadInfo",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "values": [
            {
                "label": "CATCHY-LEADFORM",
                "value": "CATCHY-LEADFORM",
            },
            {
                "label": "CATCHY-EVENT",
                "value": "CATCHY-EVENT",
            },
            {
                "label": "CATCHY-",
                "value": "CATCHY-",
            }
        ]
    },
    {
        "type": "destination",
        "icon": "fa-caret-square-down",
        "label": "Destination",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "reminderResponsible",
        "leadRequired": "client",
        "description": "Destination",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "label": "HISTO",
                "value": "HISTO"
            },
            {
                "label": "CSMO",
                "value": "CSMO"
            },
            {
                "label": "PTF LOCALE",
                "value": "PTF LOCALE"
            },
            {
                "label": "PTF NATIONALE",
                "value": "PTF NATIONALE"
            },
            {
                "label": "DEALER",
                "value": "DEALER"
            },
            {
                "label": "ZEPHIR",
                "value": "ZEPHIR"
            },
            {
                "label": "VOUS",
                "value": "VOUS"
            },
            {
                "label": "ALHENA",
                "value": "ALHENA"
            },
            {
                "label": "ARVATO",
                "value": "ARVATO"
            },
            {
                "label": "CRC",
                "value": "CRC"
            },
        ]
    },
    {
        "type": "dealer",
        "label": "Dealer",
        "icon": "fa-caret-square-down",
        "specific": true,
        "required": true,
        'isLead': true,
        "formControlName": "dealerOfInterest",
        "leadRequired": "dealer",
        "description": "Destination",
        "regex": "",
        "errorText": "Merci de remplir le champ",
        "hide": true,
        "values": [
            {
                "name": "Sans dealer",
                "value": "Sans dealer"
            },
            {
                "name": "Un dealer",
                "value": "Un dealer"
            },
            {
                "name": "Multi dealers",
                "value": "Multi dealers"
            },
            {
                "name": "Liste déroulante des dealers participants",
                "value": "Liste déroulante des dealers participants"
            },
            {
                "name": "Fichier excel CP > RRF",
                "value": "Fichier excel CP > RRF"
            },
            {
                "name": "API WIRED",
                "value": "API WIRED"
            },
        ]
    }
];

export { leadsInputsList };
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;

  public loginErrorMsg: string = "Identifiant : 'user' OR 'admin'";
  public isValidCrendentials: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private toastr: ToastrService, private authService: AuthService) {

    this.activatedRoute.queryParamMap.subscribe(params => {
      const status = params.get('status');
      if (status === 'access_denied') {
        this.toastr.warning('Utilisateur non-autorisé');
      }
    });
  }

  ngOnInit() {
  }

  connect() {
    this.authService.login();
  }
}

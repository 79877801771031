<div class="row text-left pl-2 pr-2 mb-1">
    <div class="col text-left pl-0 pr-0 ml-1">
        <!-- <a class="mb-1 home-link" routerLink="/home" style="color:#1f2532">
                        <i class="fa fa-arrow-left" style='color:#777;padding-right:10px;'></i>Retour à l'accueil</a> -->
    </div>
    <div class="col-5 text-right pl-0 pr-0 mr-1 d-flex">
        <p class="remove-filter" (click)="clearFilter()" *ngIf="searchTerm.length"><i class="la la-remove"></i> </p>
        <!-- <div class="pr-1 w-100">Date de création</div>  -->
        <!-- <input id="datePicker" class="form-control border-0" type="date" [(ngModel)]="dateValue" (change)="onDateChange($event)" (keydown)="$event.preventDefault()" placeholder="filtrer par date de création" /> -->
        <input type="text" placeholder="dates" class="form-control border-light" bsDaterangepicker
            (bsValueChange)="onValueChange($event)" [(ngModel)]="bsRangeValue"
            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default',showClearButton: true, clearPosition: 'top',showWeekNumbers: false,showPreviousMonth: true  }">
        <i title="Effacer" *ngIf="bsRangeValue" style=" margin-left: -20px;margin-top: 13px;cursor: pointer;"
            (click)="clearDateRange()" class="la la-remove"></i>
        <input type="text" class="form-control rounded-0 ml-1 border-light" [(ngModel)]="searchTerm"
            placeholder="nom,prénom,email,ipn ..." />
        <button class="btn btn-primary box-shadow-0" (click)="filterEvents()">Recherche</button>
    </div>

</div>
<table class="table table-striped mb-0">
    <thead>
        <tr>
            <th scope="col"> N°</th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='date_creation' && order==='ASC' ? sortBy('date_creation','DESC') : sortBy('date_creation','ASC')">
                Date de création
                <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='date_creation' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='date_creation' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='last_name' && order==='ASC' ? sortBy('last_name','DESC') : sortBy('last_name','ASC')">
                Nom
                <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='last_name' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='last_name' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='first_name' && order==='ASC' ? sortBy('first_name','DESC') : sortBy('first_name','ASC')">
                Prénom <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='first_name' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='first_name' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='email' && order==='ASC' ? sortBy('email','DESC') : sortBy('email','ASC')">
                Email <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='email' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='email' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='ipn' && order==='ASC' ? sortBy('ipn','DESC') : sortBy('ipn','ASC')">
                Ipn <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='ipn' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='ipn' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='event_name' && order==='ASC' ? sortBy('event_name','DESC') : sortBy('event_name','ASC')">
                Nom de l'événement<i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='event_name' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='event_name' && order==='DESC'"></i>
            </th>
            <th style="cursor:pointer" scope="col"
                (click)="field==='action' && order==='ASC' ? sortBy('action','DESC') : sortBy('action','ASC')">
                Type d'action <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                    [class.text-dark]="field==='action' && order==='ASC'"></i>
                <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                    [class.text-dark]="field==='action' && order==='DESC'"></i>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr
            *ngFor="let history of listHistory | paginate: { id:'two', itemsPerPage: itemsPerPage, currentPage: p,totalItems: total };let i=index;">
            <td>{{history.number}}</td>
            <td>{{changeFormateDate(history.creationDate) | date: 'dd-MM-yyyy HH:mm'  }}</td>
            <td>{{history.last_name}}</td>
            <td>{{history.first_name}}</td>
            <ng-template #tipContentEmail> {{history.email}}</ng-template>
            <td style='color: #1f2532;' container="body" [ngbTooltip]="history.email?.length>15 ? tipContentEmail : ''">
                {{(history.email?.length>15)? (history.email | slice:0:15)+'...':(history.email)}}
            </td>
            <td>{{history.ipn}}</td>
            <ng-template #tipContent> {{history.event_name}}</ng-template>
            <td style='color: #1f2532;' container="body" [ngbTooltip]="history.event_name?.length>15 ? tipContent : ''">
                {{(history.event_name?.length>15)? (history.event_name | slice:0:15)+'...':(history.event_name)}}
            </td>
            <td>{{history.action}}</td>
        </tr>
    </tbody>
</table>
<div class="text-center mt-0" *ngIf="listHistory.length && !isLoading">
    <div class="mt-0">
        <pagination-controls id="two" responsive="true" previousLabel="" nextLabel="" (pageChange)="pageChanged($event)">
        </pagination-controls>
    </div>
</div>
<div class="text-center" *ngIf="isLoading">
    <h5 class="text-center">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </h5>
</div>

import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ENV } from '../../core/env.config';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { decode } from 'html-entities';
import { decodeEntity } from 'html-entities';
@Component({
  selector: 'app-lead-error',
  templateUrl: './lead-error.component.html',
  styleUrls: ['./lead-error.component.css']
})
export class LeadErrorComponent implements OnInit {

  constructor(private localeService: BsLocaleService, private modalService: BsModalService, private datePipe: DatePipe, private datacatchingService: DatacatchingService, public fb: UntypedFormBuilder, private router: Router, private toastr: ToastrService) {
    this.localeService.use(this.locale);
  }
  leadObjEditable = {}
  public sfdc_url = ENV.SF_URL;
  public formLablesList:any = [];
  public sfdc_url_int = 'https://renaultarca--hquat.my.salesforce.com/';
  public sfdc_url_prod = 'https://renaultarca.my.salesforce.com/';
  public listLeads: any = [];
  public tempLeads: any = [];
  public p = 1;
  public searchTerm = "";
  public lead_id = 0;
  public isMoreinfo: boolean = false;
  public leadObj;
  public isLoading: boolean = false;
  public closeResult = '';
  public leadForm: UntypedFormGroup;
  public userForm:UntypedFormGroup =  this.fb.group({});
  public modalRef?: BsModalRef;
  public attributesList = [];
  public errorMessage = "";
  public parentKeysList = []
  public isAllLeadsChecked: boolean = false;
  public isAllLeadsCheckedDelete: boolean = false;
  public selectedLead: Object = {};
  public isExistError403: boolean = false;
  public isMoreThanOneChecked: boolean = false;
  public checkedMoreList:any =[]
  leadId;
  statusCode;
  total: number;
  itemsPerPage: number;
  formsInputs = [];
  isSendLead: boolean = false;
  dateValue = '';
  bsRangeValue;
  minDate = '';
  maxDate = '';
  locale = 'fr';
  locales = listLocales();
  
  field: String = 'date_creation';
  order: String = 'DESC';

  pageChanged(pageNum) {
    this.modifyLeads(pageNum, this.field, this.order);
  
  }
  decodeEntityHTML(val) {
    return decodeEntity(val);
  }
  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyLeads(this.p, this.field, this.order);

  }

  clearFilter() {
    this.searchTerm = '';
    this.modifyLeads(1, this.field, this.order);
  }

  filterEvents() {
    this.modifyLeads(1, this.field, this.order);
  }
  onDateChange($event) {
    this.dateValue = $event.target.value;
    this.modifyLeads(this.p, this.field, this.order);

  }

  clearDateRange() {
    this.bsRangeValue = '';
    this.minDate = '';
    this.maxDate = '';
    this.modifyLeads(this.p, this.field, this.order);
  }
  getDate(myDate) {
    return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  }

  onValueChange(value: Date): void {
    if (this.bsRangeValue !== undefined && this.bsRangeValue[0] !== undefined && this.bsRangeValue[1] !== undefined) {
      this.minDate = this.getDate(new Date(this.bsRangeValue[0]));
      this.maxDate = this.getDate(new Date(this.bsRangeValue[1]));
      this.modifyLeads(this.p, this.field, this.order);
    }

  }

  decodeValue(value) {
    return decode(value);
  }

  decodeValueError(value) {
    let val = JSON.stringify(value).replace('&lt;', '<');
    return decode(JSON.parse(val));
  }

  modifyLeads(page, field, order) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.isLoading = true;
    this.listLeads = [];
    let url = `${ENV.API_URL}v2/leads/all?field=${field}&order=${order}&success=false&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/leads/all?field=${field}&order=${order}&success=false&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&term=${this.searchTerm}&size=8`;
    }
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(lead => {
      console.log('error leads', lead);
      this.tempLeads = lead['leads'];
      this.total = lead['totalItems'];
      this.itemsPerPage = lead['itemsPerPage'];
      this.p = lead['currentPage'];
      if (!this.tempLeads) this.isLoading = false;
      if (this.tempLeads && this.tempLeads.length > 0) {
        let i = 1;
        for (let lead of this.tempLeads) {
          var leadObj = new Object();
          leadObj['number'] = JSON.stringify((page - 1) * 8 + i);
          leadObj['id_db'] = lead.id;
          leadObj['id'] = lead.lead_obj.leadInfo.leadSubmissionId ? lead.lead_obj.leadInfo.leadSubmissionId : null;
          leadObj['creationDate'] = lead.lead_obj.leadInfo.leadSubmissionTimestamp ? this.datePipe.transform(lead.lead_obj.leadInfo.leadSubmissionTimestamp, 'medium') : null;
          leadObj['lastName'] = lead.lead_obj.client.lastName ? lead.lead_obj.client.lastName : null;
          leadObj['firstName'] = lead.lead_obj.client.firstName ? lead.lead_obj.client.firstName : null;
          leadObj['mobilePhone'] = lead.lead_obj.client.mobilePhone ? lead.lead_obj.client.mobilePhone : null;
          leadObj['email'] = lead.lead_obj.client.email ? lead.lead_obj.client.email : null;
          leadObj['status'] = lead.succes ? 'création' : 'échec';
          leadObj['sfdc_id'] = lead.sfdc_id ? lead.sfdc_id : null;
          leadObj['sfdc_url'] = lead.lead_from == 'pwa' ? this.sfdc_url_prod : this.sfdc_url_int;
          leadObj['event_name'] = lead.event_name ? lead.event_name : null;
          leadObj['msg_error'] = lead.msg_error ? lead.msg_error : null;
          leadObj['status_code'] = lead.status_code !== null ? lead.status_code : null;
          leadObj['lead_obj'] = lead.lead_obj !== null ? lead.lead_obj : null;
          leadObj['form_labels_list'] = lead.form_labels_list !== null ? lead.form_labels_list : null,
          leadObj['sfdcErrorId'] = (lead.msg_error !== null && lead.msg_error[0] !== null && lead.msg_error[0].parameters.leadErrorSalesforceId !== null) ? lead.msg_error[0].parameters.leadErrorSalesforceId : null
          this.listLeads.push(leadObj);
          i++;
        }
        this.displayOrNotCheckBox();
      }
      this.isLoading = false;
    })
  }

  ngOnInit() {
    this.modifyLeads(1, this.field, this.order);
  }

  displayOrNotCheckBox() {
    this.isExistError403 = false;
    for (let index = 0; index < this.listLeads.length; index++) {
      if (this.listLeads[index].status_code == 503 || this.listLeads[index].status_code == 403) {
        this.isExistError403 = true;
        break;
      }
    }
  }

  sendLead(lead) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.isSendLead = true;
    lead.lead_obj.leadInfo.leadSubmissionTimestamp = this.transfromDate(new Date().toLocaleString());
    let url = `${ENV.API_URL}v2/leads/lead_submit_server_error`;
    let payLoad = { requestBody: lead }
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['success']) {
        this.isSendLead = false;
        this.listLeads = this.listLeads.filter(lead => lead.id_db !== res['leadId']);
        this.toastr.success("Le lead envoyé avec succès");
        this.displayOrNotCheckBox();
      }
    })
  }

  sendAllLeads() {

    let isTokenNotExpired = this.datacatchingService.TokenValid();
    let requestsList = [];
    let leadsList = this.listLeads.filter(lead => lead.hasOwnProperty('state') && lead.state === true);
    let url = `${ENV.API_URL}v2/leads/lead_submit_server_error`;
    for (let lead of leadsList) {
      lead.lead_obj.leadInfo.leadSubmissionTimestamp = this.transfromDate(new Date().toLocaleString());
      let payLoad = { requestBody: lead }
      requestsList.push(this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal')); 
    }
    forkJoin(requestsList).subscribe(responsList => {
      for (let res of responsList) {
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        if (res['success']) {
          this.listLeads = this.listLeads.filter(lead => lead.id_db !== res['leadId']);
        }
      }
      this.displayOrNotCheckBox();
      this.toastr.success("Les leads envoyés avec succès");
    });
  }
  changeDate(d) {
    let splittedDate = d.split("T").join(' ');
    let date1 = splittedDate.slice(0, splittedDate.lastIndexOf("."));
    return date1;
  }

  moreInfo(id) {
    this.lead_id = id;
    let lead = this.tempLeads.filter(l => l.id == id);
    this.leadObj = lead[0];
    this.isMoreinfo = true;

  }

  gotoSalesforce() {

  }


  async DeleteAllLeads() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let requestsList = [];
    let leadsList = this.listLeads.filter(lead => lead.hasOwnProperty('state') && lead.state === true);
    console.log("leadsList", leadsList);

    for (let lead of leadsList) {
      let url = `${ENV.API_URL}v2/leads/lead_error/delete/${lead.id_db}`;
      requestsList.push(this.datacatchingService.deleteApi(url, isTokenNotExpired, 'normal'));
    }
    forkJoin(requestsList).subscribe(responsList => {
      for (let res of responsList) {
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        if (res['success']) {
          this.listLeads = this.listLeads.filter(lead => lead.id_db !== res['id']);
        }
      }
      this.isAllLeadsCheckedDelete = false;
      this.isMoreThanOneChecked = false;
      this.checkedMoreList = [];
      let checkbox = document.getElementById("checkbox") as HTMLInputElement
      document.getElementById("cbx")['checked'] = false;
      this.modifyLeads(1, this.field, this.order);

      this.toastr.success("Les leads a été supprimé avec succès");
    });
  }
  checkAll(ev) {
    this.isAllLeadsChecked = ev.target.checked; 
    for (let lead of this.listLeads) {
      if (lead.status_code >= 500) {
        lead.state = ev.target.checked; 
      }
    }
  }

  isAllChecked() {
    return this.listLeads.every(_ => _.state);
  }
 

  changeFormateDate(d) {
    let formatedDate;
    let date = d.split('T');
    let removeZone = date[1].split(".");
    formatedDate = date[0] + " " + removeZone[0];
    return formatedDate;
  }

  copyInputMessage(textToCopy) {
    const create_copy = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', textToCopy);
      e.preventDefault();
    };
    document.addEventListener('copy', create_copy);
    document.execCommand('copy');
    document.removeEventListener('copy', create_copy);
    this.toastr.success("Lead submission Id est copié")
  }

  OnSelect(id) {
    this.router.navigate(['/lead-details', id])
  }
  openModal(template: TemplateRef<any>, id) {
    this.lead_id = id;
    let lead = this.tempLeads.filter(l => l.id == id);
    this.leadObj = lead[0];
    this.modalRef = this.modalService.show(template, { class: 'modal-lg',animated:false });
  }
  openModalError(errorMessageTemplate, id) {
    let lead = this.tempLeads.filter(l => l.id == id);
    this.leadObj = lead[0];
    console.log('lead', this.leadObj);
    this.statusCode = this.leadObj.status_code;
    this.errorMessage = JSON.stringify(this.leadObj.msg_error);
    this.modalRef = this.modalService.show(errorMessageTemplate, { class: 'modal-lg', animated: false });
  }
  

  async openModalEdit(templateEdit, id) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.userForm = this.fb.group({});
    let lead = this.tempLeads.filter(l => l.id == id);
    this.selectedLead = lead[0];
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.lead_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
                this.userForm.addControl(childKey, new UntypedFormControl(childValue));
              } else {
                if ( childValue !==null  && Object.keys(childValue).length>0) {
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                    this.userForm.addControl(grandChildKey, new UntypedFormControl(grandChildValue));
                    let label = this.formLablesList.filter(label => label.formControlName == grandChildKey);
                    this.formsInputs.push({ formControlName: `${[grandChildKey]}`, label: label.length ? label[0].formControlLabel : grandChildKey, parentKey: label[0].parentKey, value: grandChildValue })
                  }
                }
              }
            }
          }
        }
        this.userForm.patchValue(formGroupAttributes);
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }
  }
  transfromDate(date) {
    date = date.split('/').join('-').replace(',', '');
    let onlyDate = date.split(" ");
    let formatChangedDate = onlyDate[0].split("-");
    let d = `${formatChangedDate[2]}-${formatChangedDate[1]}-${formatChangedDate[0]}T${onlyDate[1]}.000Z`;
    return d;
  }

  onSubmitLeadObj(val: any) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.isSendLead = true;
    let leadObjEditable = JSON.parse(val);
    let requestBody = {
      lead: leadObjEditable,
      event_name: this.selectedLead['event_name'],
      cts: leadObjEditable['leadInfo']['leadSubmissionTimestamp'],
      lead_from: 'app',
      current_lead_submission_id: leadObjEditable['leadInfo'].leadSubmissionId,
      id: this.selectedLead['id']
    }
    let url = `${ENV.API_URL}v2/leads/lead_submit_after_error_correction`;
    let payLoad = { requestBody: requestBody };
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {
      this.isSendLead = false;
      if (res['success']) {
        this.toastr.success("Lead envoyé avec succès");
        this.listLeads = this.listLeads.filter(lead => lead.id_db !== this.selectedLead['id']);
        this.modalRef.hide();
      } else {
        this.toastr.error("Lead non envoyé, veuillez vérifier les informations du lead");
      }
    });
  }

  onLeadObjEdit(leadObj) {
    this.leadObjEditable = leadObj;
  }

  onSubmit() {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.isSendLead = true;
    let arr = [];
    let leadObj = {};
    console.log("form Values", this.userForm.getRawValue());
    for (let [key, value] of Object.entries(this.userForm.getRawValue())) { 
      arr.push({  formControlName: `${[key]}`, value:value });
    }
    for (let v of arr) {
      for (let ip of this.formsInputs) {
        if (ip.formControlName === v.formControlName ) {
           ip.value = v.value
         }
      }
    }
    for (let parentKey of this.parentKeysList) {
      let tempArr = [];
      tempArr = this.formsInputs.filter(fI => fI.parentKey === parentKey || fI.parentKey.includes(parentKey) );
      if (tempArr.length) {
        let keyValues = []
        for (let val of tempArr) {
          if (!val.parentKey.includes(".")) {
            let arr = [val.formControlName, val.value];
            keyValues.push(arr);
          } else {
            let nestedKey = val.parentKey.split(".");
            let arr = [`${nestedKey[1]}.${val.formControlName}` , val.value];
            keyValues.push(arr);
          }
        }
        let obj = {};
        obj = Object.fromEntries(keyValues);
        for (const [key, value] of Object.entries(obj)) {
          let innerObj = {}
          if (key.includes(".")) {
            let splittedKey = key.split(".");
            innerObj[`${splittedKey[0]}`] = {
              [`${splittedKey[1]}`] : value
            }
            delete obj[key];
            Object.assign(obj, innerObj)
          }
        }
        leadObj[parentKey] = obj;
      }  
    }

    let requestBody = {
      lead:leadObj,
      event_name: this.selectedLead['event_name'],
      cts: leadObj['leadInfo']['leadSubmissionTimestamp'],
      lead_from: 'app',
      current_lead_submission_id: leadObj['leadInfo'].leadSubmissionId,
      id: this.selectedLead['id']
    }
    let url = `${ENV.API_URL}v2/leads/lead_submit_after_error_correction`;
    let payLoad = { requestBody: requestBody };
    this.datacatchingService.postApi(url, payLoad, isTokenNotExpired, 'normal').subscribe(res => {
      this.isSendLead = false;
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['success']) {
        this.toastr.success("Lead envoyé avec succès");
        this.listLeads = this.listLeads.filter(lead => lead.id_db !== this.selectedLead['id']);
        this.modalRef.hide();
      } else {
        this.toastr.error("Lead non envoyé, veuillez vérifier les informations du lead");
      }
    });
  }

  
  checkKey(key) {  
    let list = this.formsInputs.filter(formInput => formInput.parentKey === key);
    return list.length ? false : true;
  }

  onDeleteLead(templateDelete, id) {
    this.leadId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
  }


  async onDelete() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/leads/lead_error/delete/${this.leadId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result']['rowCount'] === 1) { 
        this.modifyLeads(this.p, this.field, this.order);
        this.modalRef.hide();
        this.toastr.success("Lead a été supprimé avec succès");

      }
    })
  }
  checkAllDelete(ev) {
    this.isAllLeadsCheckedDelete = ev.target.checked;
    this.isMoreThanOneChecked = ev.target.checked;
    for (let lead of this.listLeads) {
      lead.state = ev.target.checked;
      if (ev.target.checked) this.checkedMoreList.push(lead.id);
      if (!ev.target.checked) this.checkedMoreList = this.checkedMoreList.filter(i => i !== lead.id);
    }
  }
  checkLeadDelete(ev, id) {
   
    if (ev.target.checked) {
      this.checkedMoreList.push(id)
    }
    if (!ev.target.checked) {
      this.checkedMoreList = this.checkedMoreList.filter(i => i !== id);
    }
    if (this.checkedMoreList.length > 0) {
      this.isMoreThanOneChecked = true
    }
    if (this.checkedMoreList.length<1) {
      this.isMoreThanOneChecked = false
    }
    for (let lead of this.listLeads) {
      if (lead.id === id) {
        lead.state = ev.target.checked;
      }
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterPipe'
})
export class SearchTermPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!args) {
            return value;
        }
        return value.filter((val) => {
            console.log('args', args);
            let rVal = (val.event_name.toLocaleLowerCase().includes(args.toLocaleLowerCase()));
            return rVal;
        })

    }

}
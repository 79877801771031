<div class="app-conent content  pt-2 mb-2">

    <div class="app-conent content mt-2 pt-3 mb-2">
    </div>
        <tabset>
            <tab heading="Traduction" id="tab1" class="m-1">
                <div class="w-25 ml-auto mb-1 text-right">
                    <button type="button" class="btn btn-primary btn-primary-r mr-1" (click)="onAddLanguage(templateAdd,'add', null)"><i
                            class="fas fa-plus" style="padding-right: 5px;"></i>
                        Ajouter un nouveau</button>
                </div>
                <div class="table-wrapper m-1 mb-2">
                
                
                
                    <table class="table bg-white table-striped">
                        <thead>
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Langue</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let translation of translations;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{translation.language}}</td>
                                <td><ng-template #tipContentEdit>Modifier</ng-template>
                                    <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit"
                                        (click)="onAddLanguage(templateAdd, 'edit',translation.id)">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <ng-template #tipContentDelete>Suprrimer</ng-template>
                                    <button type="button" class="btn btn-danger btn-sm mr-1"
                                        (click)="onDeleteLanguage(templateDelete,translation.id)" [ngbTooltip]="tipContentDelete">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ng-template #templateAdd>
                    <div class="modal-header border-0 text-white text-center bg-white p-0 pr-1 pt-1">
                        <!-- <h4 class="modal-title text-white ml-auto text-center">Ajouter L'Emails</h4> -->
                        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
                            (click)="modalRef?.hide()">
                            <span aria-hidden="true" class="visually-hidden" style="color: black !important">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
                        <div>
                            <form class="mt-2" [formGroup]="translationForm">
                
                                <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                    <ngb-panel id="static-1">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>General inputs</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                
                                            </div>
                                        </ng-template>
                
                
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Language<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="language" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Home button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="home_btn" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Start button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="start_btn" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Return button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="return_btn" class="form-control">
                                            </div>
                
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Close button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="close_btn" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Loading text <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="loading_text"
                                                    class="form-control">
                                            </div>
                
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Select option placeholder <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="select_placeholder"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Telephone validation error message <span
                                                        class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="telephone_valid"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Mandatory message <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="mandatory_message"
                                                    class="form-control">
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-2">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>RRF multi dealers fields</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                                            </div>
                                        </ng-template>
                
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Rrf Label <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="label_rrf" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Rrf placeholder <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Rrf placeholder <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error message RRf (1) <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageMinLength_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error message RRf(2) <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageMandatory_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error message RRf(3) <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageNotNumber_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error message RRf(4) <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageNotFound_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Country <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="country_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer Name <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="nameDealer_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer code <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="codeDealer_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Nature <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="nature_rrf" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Address <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="address_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dr <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="dr_rrf" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Restart button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="restartBtn_rrf"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Validate button <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="validateBtn_rrf"
                                                    class="form-control">
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-3">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>Ipn vendeur</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                                            </div>
                                        </ng-template>
                
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Ipn input label <span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="label_ipn" class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Ipn placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_ipn"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Ipn error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_regex_ipn"
                                                    class="form-control">
                                            </div>
                                            <h4 class="mb-3 bg-light p-1">Campagne</h4>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Campaign input label<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="label_campaign"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Campaign placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_campaign"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Campaign error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_campaign"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Label host<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="label_hostesses"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Label seller<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="label_vendeur"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Continue button<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="continue_btn"
                                                    class="form-control">
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-4">
                
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>Brand,Model and Energy inputs</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Brand placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_brand"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Brand error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_brand"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Model placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_model"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Model error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_model"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Energy placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_energy"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Energy error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_energy"
                                                    class="form-control">
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-5">
                
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>Dealer input</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Choose dealer label<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="choose_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer selection label<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="choose_affaire_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageMandatory_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Code postal error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_cp"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Api no results message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessageNoresult_cp"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Code postal placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_cp"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Code postal dealer placeholder<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="placeholder_cp_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer selection error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_selection_dealer"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Dealer config error message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="errorMessage_dealer_config"
                                                    class="form-control">
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-6">
                
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="accordion-button d-flex bg-light" [class.collapsed]="!opened">
                                                <button ngbPanelToggle
                                                    class="p-2 btn btn-link container-fluid text-start ps-0 text-left d-flex justify-content-between">
                                                    <span>Messages</span>
                                                    <div>
                                                        <i *ngIf="opened" class="fa fa-chevron-up"></i>
                                                        <i *ngIf="!opened" class="fa fa-chevron-down"></i>
                                                    </div>
                
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error event not started or code secret<span
                                                        class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="event_not_started_error"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error event expired message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="error_event_expire"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error event contact admin message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="error_contact_admin"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error in secret code message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="error_code_secret"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error event pause message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="error_event_pause"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Error form fields message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="error_form_fields"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Form submit success message<span class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="form_success"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group text-left mb-1">
                                                <label for="email">Form submit offline success message<span
                                                        class="star">*</span></label>
                                                <input type="text" spellcheck="false" formControlName="form_offline_submit_success"
                                                    class="form-control">
                                            </div>
                
                
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                
                                <div class="d-flex justify-content-between mt-3">
                                    <div>
                                        <button type="button" class="btn btn-light text-secondary border-0 rounded-0"
                                            (click)="onCancel()"> <i class="fas fa-times"
                                                style="padding-right: 5px;"></i>Annuler</button>
                                    </div>
                                    <div>
                                        <button *ngIf="!isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"
                                            [disabled]="!translationForm.valid" (click)="onSubmit()"> <i class="fas fa-save"
                                                style="padding-right: 5px;"></i>Enregistrer</button>
                                        <button *ngIf="isEdit" type="button" class="btn btn-primary btn-primary-r border-0c"
                                            [disabled]="!translationForm.valid" (click)="onUpdate()"> <i class="fas fa-save"
                                                style="padding-right: 5px;"></i>Enregistrer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
                <ng-template #templateDelete>
                    <div class="modal-header border-0 text-white text-center bg-danger">
                        <h4 class="modal-title text-white ml-auto text-center">Supprimer le traduction</h4>
                        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
                            (click)="modalRef?.hide()">
                            <span aria-hidden="true" class="visually-hidden">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-dark p-2  edit-lead-modal-body">
                        <p class="text-center">Voulez-vous supprimer le traduction?</p>
                        <div class="d-flex justify-content-between">
                
                            <div>
                                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                                    style="padding: 0.4rem 1rem !important;">Non</button>
                            </div>
                            <div>
                                <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </tab>
            <tab heading="Regex" class="m-1">
               <app-translation-regex></app-translation-regex>
            </tab>
        </tabset>
</div>
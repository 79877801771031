import { Component, OnInit, ElementRef, TemplateRef, ViewChild, Input } from '@angular/core';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @ViewChild('modal') 
  private template : TemplateRef<any>;
  @Input() loading: boolean;
  
  constructor() { }
  ngOnInit() {
  }
}

import { Component, OnInit } from '@angular/core';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { AuthService } from '../../services/auth.service'

declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit {
  userName: any;
  id: any;
  public isCollapsed = false;


  constructor(private authService: AuthService, private datacatchingService: DatacatchingService,) { }

  ngOnInit() {
    this.userName = JSON.parse(sessionStorage.getItem("userName"));
    this.id = JSON.parse(sessionStorage.getItem("id"));
    this.datacatchingService.toggleMenu$.subscribe(cls => {
      $(".page-wrapper").removeClass(cls);

    })
  }

  resetEvent() {
    if("id" in sessionStorage) sessionStorage.removeItem("id");
  }

  logout() {
    this.authService.logoff()
  }

  toggleSideBar() {
    $(".page-wrapper").addClass("toggled");
    // $("#show-sidebar").click(function() {

    // });
  }
  closeSideBar() {
    $(".page-wrapper").removeClass("toggled");
    // $("#close-sidebar").click(function() {

    // });
  }

}

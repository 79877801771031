import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ENV } from '../../core/env.config';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-lead-timing',
  templateUrl: './lead-timing.component.html',
  styleUrls: ['./lead-timing.component.css']
})
export class LeadTimingComponent implements OnInit {

  public sfdc_url = ENV.SF_URL;
  public listLeads: any = [];
  public listLeadsTemp: any = [];
  public p = 1;
  public searchTerm = "";
  public lead_id = 0;
  public isMoreinfo: boolean = false;
  public leadObj;
  public isLoading: boolean = false;
  public attributesList = [];
  public parentKeysList = [];
  public selectedLead;
  public formsInputs;
  public userForm: UntypedFormGroup = this.fb.group({});
  public formLablesList;
  public modalRef?: BsModalRef;
  public tempLeads: any = [];
  total: number;
  itemsPerPage: number;
  field: string = 'leadSubmissionTimestamp';
  order: string = 'DESC';
  dateValue = '';
  bsRangeValue;
  minDate = '';
  maxDate = '';
  locale = 'fr';
  locales = listLocales();
  leadId;
  isAllLeadsChecked: boolean = false;
  dateTempo:string;
  minDateTempo;
  minDateError: boolean = false;
  isMoreThanOneChecked: boolean = false;
 checkedMoreList: any = []
  constructor(private toastr: ToastrService,private localeService: BsLocaleService, public fb: UntypedFormBuilder, private datacatchingService: DatacatchingService, private modalService: BsModalService, private datePipe: DatePipe) {
    this.localeService.use(this.locale);
    this.minDateTempo = new Date().toISOString().substring(0, 16);
   }
  onDateTempoChange(date) {
    if (new Date(date).getTime() < new Date().getTime()) {
      this.minDateError = true;
    } else {
      this.minDateError = false;
      this.dateTempo = date;
    }
    
  }

  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyLeads(this.p, this.field, this.order);

  }
  onDateChange($event) {
    this.dateValue = $event.target.value;
    this.modifyLeads(1, this.field, this.order);

  }

  pageChanged(pageNum) {
    this.p = pageNum;
    this.modifyLeads(pageNum, this.field, this.order);
  }
  filterEvents() {
    this.modifyLeads(1, this.field, this.order);
  }

  clearFilter() {
    this.searchTerm = '';
    this.modifyLeads(1, this.field, this.order);
  }
  clearDateRange() {
    this.bsRangeValue = '';
    this.minDate = '';
    this.maxDate = '';
    this.modifyLeads(1, this.field, this.order);
  }
  getDate(myDate) {
    return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  }

  onValueChange(value: Date): void {
    console.log('bsRangeValue;', this.bsRangeValue);
    if (this.bsRangeValue !== undefined && this.bsRangeValue[0] !== undefined && this.bsRangeValue[1] !== undefined) {
      this.minDate = this.getDate(new Date(this.bsRangeValue[0]));
      this.maxDate = this.getDate(new Date(this.bsRangeValue[1]));
      this.modifyLeads(1, this.field, this.order);
    }

  }
  

 async  modifyLeads(page: number, field: string, order: string) { 
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.listLeads = [];
    this.isLoading = true;
    this.listLeadsTemp = [];
    
    let url = `${ENV.API_URL}v2/leads/lead_tempo?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/leads/lead_tempo?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8&term=${this.searchTerm}`;
    }
    
   this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(leads => {
      this.listLeadsTemp = leads['leads'];
      this.tempLeads = leads['leads'];
      this.total = leads['totalItems'];
      this.itemsPerPage = leads['itemsPerPage'];
      this.p = leads['currentPage'];
      if (!this.listLeadsTemp) this.isLoading = false;

      if (this.listLeadsTemp && this.listLeadsTemp.length > 0) { 
        var i = 1;
        for (let lead of this.listLeadsTemp) {
          var leadObj = new Object();
          leadObj['number'] = JSON.stringify((page - 1) * 8 + i);
          leadObj['id'] = lead.id;
          leadObj['creationDate'] = lead?.form_obj.leadInfo?.leadSubmissionTimestamp ? this.datePipe.transform(lead.form_obj.leadInfo.leadSubmissionTimestamp, 'medium') : null;
          leadObj['creationDateTempo'] = lead.date_tempo ? lead.date_tempo : null;
          leadObj['lastName'] = lead.form_obj.client.lastName ? lead.form_obj.client.lastName : null;
          leadObj['firstName'] = lead.form_obj.client.firstName ? lead.form_obj.client.firstName : null;
          leadObj['mobilePhone'] = lead.form_obj.client.mobilePhone ? lead.form_obj.client.mobilePhone : null;
          leadObj['status'] = 'En cours';
          leadObj['event_name'] = lead.event_name ? lead.event_name : null;
          this.listLeads.push(leadObj);
          i++;
        }
      }
      this.isLoading = false;
    })

  }
  ngOnInit() {
    this.modifyLeads(1, this.field, this.order);
  }


  moreInfo(id) {
    console.log('id', id);
    this.lead_id = id;
    let lead = this.listLeadsTemp.filter(l => l.id == id);
    console.log(lead);
    this.leadObj = lead[0];
    this.isMoreinfo = true;

  }
  checkAll(ev) {
    this.isAllLeadsChecked = ev.target.checked;
    this.isMoreThanOneChecked = ev.target.checked;
    for (let lead of this.listLeads) {
      lead.state = ev.target.checked;
      if (ev.target.checked) this.checkedMoreList.push(lead.id);
      if (!ev.target.checked) this.checkedMoreList = this.checkedMoreList.filter(i => i !== lead.id);
    }
  }
  checkLead(ev, id) {
    if (ev.target.checked) {
      this.checkedMoreList.push(id)
    }
    if (!ev.target.checked) {
      this.checkedMoreList = this.checkedMoreList.filter(i => i !== id);
    }
    if (this.checkedMoreList.length > 0) {
      this.isMoreThanOneChecked = true
    }
    if (this.checkedMoreList.length < 1) {
      this.isMoreThanOneChecked = false
    }
    for (let lead of this.listLeads) {
      if (lead.id === id) {
        lead.state = ev.target.checked;
      }
    }
  }



  
  async DeleteAllLeads() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let requestsList = [];
    let leadsList = this.listLeads.filter(lead => lead.hasOwnProperty('state') && lead.state === true);
    console.log("leadsList", leadsList);
 
    for (let lead of leadsList) {
      let url = `${ENV.API_URL}lead_tempo/delete/${lead.id}`;
      requestsList.push(this.datacatchingService.deleteApi(url, isTokenNotExpired, 'normal'));
    }
    forkJoin(requestsList).subscribe(responsList => {
      for (let res of responsList) {
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        if (res['result']['rowCount'] === 1) { 
          this.listLeads = this.listLeads.filter(lead => lead.id_db !== res['id']);
        }
      }
      this.isAllLeadsChecked = false;
      this.isMoreThanOneChecked = false;
      this.checkedMoreList = [];
      this.modifyLeads(this.p, this.field, this.order);

      this.toastr.success("Les leads a été supprimé avec succès");
    });
  }


  gotoSalesforce() {

  }

  changeDate(d) {
    if (d != null && d != undefined) {
      let splittedDate = d.split("T").join(' ');
      let dt = splittedDate.split(" ");
      let justDate = dt[0];
      let justDateSplitted = justDate.split("-");
      let date1 = splittedDate.slice(0, splittedDate.lastIndexOf("."));
      return date1;
    }

  }

  changeFormateDate(d) {

    let formatedDate;
    let date = d.split('T');
    let removeZone = date[1].split(".");
    formatedDate = date[0] + " " + removeZone[0];
    return formatedDate;
  }

  async openModalInfo(templateEdit, id) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let lead = this.tempLeads.filter(l => l.id == id);
 
    this.selectedLead = lead[0];
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    console.log('attributes', attributes);
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.form_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
              } else {
               
                if (childValue !== null && Object.keys(childValue).length > 0) {
                  console.log('child Value', childValue)
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                  }
                }
              }
            }
          }
        }
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }
  }
  onDeleteLead(templateDelete, id) {
    this.leadId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
  }
  async onDelete() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/leads/lead_tempo/delete/${this.leadId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result']['rowCount'] === 1) { 
        this.modifyLeads(this.p, this.field, this.order);
        this.modalRef.hide();
        this.toastr.success("Lead a été supprimé avec succès");
      
      }
    })
    
  }

  async openModalEditLead(templateEdit, id) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.userForm = this.fb.group({});
    let lead = this.tempLeads.filter(l => l.id == id);
    console.log("lead", lead);
    
    this.selectedLead = lead[0];
    console.log('lead', lead);
    this.dateTempo = new Date(lead[0].date_tempo).toISOString().slice(0, 16);
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.form_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
                this.userForm.addControl(childKey, new UntypedFormControl(childValue));
              } else {
                if (childValue !== null && Object.keys(childValue).length > 0) {
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                    this.userForm.addControl(grandChildKey, new UntypedFormControl(grandChildValue));
                    let label = this.formLablesList.filter(label => label.formControlName == grandChildKey);
                    this.formsInputs.push({ formControlName: `${[grandChildKey]}`, label: label.length ? label[0].formControlLabel : grandChildKey, parentKey: label[0].parentKey, value: grandChildValue })
                  }
                }
              }
            }
          }
        }
        this.userForm.patchValue(formGroupAttributes);
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }
  }
  async onSubmit() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    // this.isSendLead = true;
    let arr = [];
    let leadObj = {};
    for (let [key, value] of Object.entries(this.userForm.getRawValue())) {
      arr.push({ formControlName: `${[key]}`, value: value });
    }
    for (let v of arr) {
      for (let ip of this.formsInputs) {
        if (ip.formControlName === v.formControlName) {
          ip.value = v.value
        }
      }
    }
    for (let parentKey of this.parentKeysList) {
      let tempArr = [];
      tempArr = this.formsInputs.filter(fI => fI.parentKey === parentKey || fI.parentKey.includes(parentKey));
      if (tempArr.length) {
        let keyValues = []
        for (let val of tempArr) {
          if (!val.parentKey.includes(".")) {
            let arr = [val.formControlName, val.value];
            keyValues.push(arr);
          } else {
            let nestedKey = val.parentKey.split(".");
            let arr = [`${nestedKey[1]}.${val.formControlName}`, val.value];
            keyValues.push(arr);
          }
        }
        let obj = {};
        obj = Object.fromEntries(keyValues);
        for (const [key, value] of Object.entries(obj)) {
          let innerObj = {}
          if (key.includes(".")) {
            let splittedKey = key.split(".");
            innerObj[`${splittedKey[0]}`] = {
              [`${splittedKey[1]}`]: value
            }
            delete obj[key];
            Object.assign(obj, innerObj)
          }
        }
        leadObj[parentKey] = obj;
      }
    }
    console.log("dateTempo", this.dateTempo);
    let requestBody = {
      lead: leadObj,
      event_name: this.selectedLead['event_name'],
      cts: leadObj['leadInfo']['leadSubmissionTimestamp'],
      lead_from: 'app',
      id: this.selectedLead['id']
    }
    this.datacatchingService.updateApi(`${ENV.API_URL}v2/leads/lead_tempo/update`, { form_obj: leadObj, event_id: this.selectedLead['id'], dateTempo: `${this.dateTempo}:00.000Z` }, isTokenNotExpired).subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result']['rowCount'] === 1) {
        this.isAllLeadsChecked = false;
        this.modifyLeads(this.p, this.field, this.order);
        this.modalRef.hide();
        this.toastr.success("Lead a été mis à jour avec succès");
      }
    }) 
  }
  checkKey(key) {
    let list = this.formsInputs.filter(formInput => formInput.parentKey === key);
    return list.length ? false : true;
  }

}

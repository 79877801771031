import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter'
})
export class SearchPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!args) {
            return value;
        }
        return value.filter((val) => {
            let rVal =
                (val.firstName !== undefined && val.firstName !== null && val.firstName.toLocaleLowerCase().includes(args.toLocaleLowerCase())
                    || (val.lastName !== undefined && val.lastName != null && val.lastName.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                    || (val.event_name !== undefined && val.event_name != null && val.event_name.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                    || (val.creationDate !== undefined && val.creationDate !== null && val.creationDate.toLocaleLowerCase().includes(args.toLocaleLowerCase()))
                    || (val.number !== undefined && val.number !== null && val.number.includes(args))
                    || (val.status !== undefined && val.status !== null && val.status.includes(args))
                    || (val.id !== undefined && val.id !== null && val.id.includes(args))
                    || (val.sfdcErrorId !== undefined && val.sfdcErrorId !== null && val.sfdcErrorId.includes(args))
                    || (val.status_code !== undefined && val.status_code !== null && JSON.stringify(val.status_code).includes(args))
                ); 
            return rVal;
        })

    }

}
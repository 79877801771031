import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DatacatchingService } from './services/datacatching.service';
import { IndexeDBService } from './services/indexe-db.service'
import { ENV } from './core/env.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public title = 'solution-datacatching-dcf';
  public update: boolean = false;
  public tempDrs: any = [];

  constructor(update: SwUpdate, private datacatchingService: DatacatchingService) {
   
    update.available.subscribe(event => {
      update.activateUpdate().then(() => document.location.reload());
    })
  }
  ngOnInit(): void {

  }
}

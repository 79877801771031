import { Directive, HostListener } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]'
})

export class MaxLengthDirective {
  constructor(private readonly formControl: FormControlName) {}
  @HostListener('keyup', ['$event'])
  onKeyUp(event): void {
    const value = this.formControl.value;
    console.log('value', value)
    if (value.length > 2) {
      event.preventDefault();
    }
  }

}